import React, { useState } from "react";
import {
  Accordion,
  Button,
  Card,
  CardGroup,
  Figure,
  Modal,
} from "react-bootstrap";
import "./about.css";
import almacenar from "../../Media/videobusiness.mp4";
import Footer from "../../Layouts/Footer";

const About = () => {
  const [filosofiashow, setShowfilosofia] = useState(false);
  const handleClosefilosofia = () => setShowfilosofia(false);
  const handleShowfilosofia = () => setShowfilosofia(true);

  const [quehacemosshow, setShowquehacemos] = useState(false);
  const handleClosequehacemos = () => setShowquehacemos(false);
  const handleShowquehacemos = () => setShowquehacemos(true);
  return (
    <div>
      <div className="cover-container-about">
        <video
          className="video-about"
          src={almacenar}
          autoPlay
          loop
          muted
        ></video>
        <h2>Quiénes somos</h2>

        <p className="container">
          Somos una empresa proveedora de soluciones de tecnología de la
          información fundada en el año 2002 por profesionales expertos
          comprometidos en apoyar la transformación digital y la continuidad de
          las operaciones de entidades locales e internacionales. Nuestro reto
          constante es garantizar precios competitivos, productos innovadores y
          el cumplimiento de niveles de servicio con responsabilidad.
        </p>
      </div>
      <br />

      <CardGroup>
        <Card onClick={handleShowfilosofia}>
          <Card.Img
            variant="top"
            src="./img/Quehacemos.jpg"
            className="capa-filosofia bg-black"
          />
          <Card.ImgOverlay
            className="capa-seguridad"
            to="/Seguridad-de-Nubes-y-aplicaciones"
            style={{ textDecoration: "none" }}
          >
            <Card.Title className="text-white">
              <h3>Filosofía empresarial</h3>
            </Card.Title>
          </Card.ImgOverlay>
        </Card>
        <Modal
          show={filosofiashow}
          onHide={handleClosefilosofia}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filosofía Empresarial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Figure>
              <Figure.Image
                width={500}
                height={300}
                alt=""
                src="./img/filosofia2.jpg"
                className="container"
              />
              <Figure.Caption>
                <h5>-Nuestra Filosofía:</h5>
                <br />

                <h6>
                  -Trabajamos con aliados de negocios confiables para lograr un
                  crecimiento estable, basado en negocios que produzcan
                  beneficios mutuos. Promovemos un ambiente familiar en el
                  trabajo que provee solidaridad y amistad. Tratamos a todas las
                  personas con respeto y cortesía y creamos relaciones duraderas
                  basadas en la amistad y la justicia. Trabajamos en equipo y
                  compartimos el conocimiento. Actuamos con honestidad y siempre
                  cumplimos nuestras promesas. Somos buenos ciudadanos y todas
                  nuestras actividades están dentro de la ley y los valores
                  éticos. Protegemos la relación con nuestros clientes y
                  respondemos con prontitud y oportunidad a sus necesidades.
                </h6>
              </Figure.Caption>
            </Figure>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleClosefilosofia}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Card onClick={handleShowquehacemos}>
          <Card.Img
            variant="top"
            src="./img/Filosofia.jpg"
            className="capa-quehacemos bg-black"
          />
          <Card.ImgOverlay
            className="capa-seguridad"
            to="/Seguridad-Industrial"
            style={{ textDecoration: "none" }}
          >
            <Card.Title className="text-white">
              <h3>Lo que hacemos</h3>
            </Card.Title>
          </Card.ImgOverlay>
        </Card>

        <Modal
          show={quehacemosshow}
          onHide={handleClosequehacemos}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Que hacemos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Figure>
              <Figure.Image
                width={1}
                height={300}
                alt=""
                src="./img/quehacemos2.jpg"
                className="container"
              />
              <Figure.Caption>
                <h5>-Lo que hacemos:</h5>
                <br />
                <h6>
                  -Nos especializamos en implantaciones de soluciones de
                  almacenamiento, respaldo y archiving de productos fabricados
                  por las empresas: Dell Technologies, HPE, Vmware, Solarwinds,
                  Quantum, CISCO, Veeam, Microsoft.
                </h6>
              </Figure.Caption>
            </Figure>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleClosequehacemos}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </CardGroup>

      <div>
        <div className="libreria-container-beneficios ">
          <div className="libreria-desc-beneficios">
            <Accordion defaultActiveKey={["0"]}>
              <Accordion.Item eventKey="0" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Misión:</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Proveer, soportar y administrar medios de tecnología de
                    información a entidades con necesidades críticas por medio
                    de un alto nivel de servicio y compromiso con sus negocios,
                    aportando beneficios a nuestros interesados.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Visión:</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Ser el principal asesor y soporte en mitigación de riesgos
                    de tecnología en la República Dominicana.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Valores:</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Compromiso, Integridad, Entrega, Responsabilidad, Respeto,
                    Empatía.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Principios:</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Trabajamos con aliados de negocios confiables para lograr un
                    crecimiento estable basado en negocios lícitos que produzcan
                    beneficios mutuos. Protegemos la relación con nuestros
                    clientes y respondemos con prontitud y oportunidad a sus
                    necesidades. Actuamos con honestidad y siempre cumplimos
                    nuestras promesas. Somos buenos ciudadanos y todas nuestras
                    actividades están dentro de la ley y los valores éticos.
                    Trabajamos como equipo y compartimos el conocimiento.
                    Promovemos un ambiente familiar en el trabajo que provea
                    solidaridad y amistad. Tratamos a todas las personas con
                    respecto y cortesía y creamos relaciones duraderas basadas
                    en la amistad y justeza.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        <br />
      </div>

      <Footer />
    </div>
  );
};

export default About;
