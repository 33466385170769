import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import Footer from "../../../../Layouts/Footer";
import "../networking.css";

const Plataformas = () => {
  return (
    <>
      <div className="Virtual-Edge-Platform">
        <Card
          className="servicio text-center text-light "
          bg="black"
          border="light"
        >
          <h1>Virtual Edge Platform</h1>
        </Card>
        <CardGroup className="container">
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/Edge-platform4600.avif"
                height={350}
              />
            </div>

            <Card.Body>
              <Card.Title className="text-info">
                <h2>Dell Virtual Edge Platform 4600</h2>
              </Card.Title>
              <br />
              <Card.Text>
                <h6>
                Esta VEP es un uCPE modular abierto de alto rendimiento optimizado para alojar VNF. Idónea para SD-WAN. Perfecto para el perímetro de SP o sucursales empresariales.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Procesador de alto rendimiento Intel Xeon-D 2100 optimizado para redes.
                </h6>
                <h6>-Actualizable con interfaces o tarjetas de expansión Wi-Fi.</h6>
                <h6>-Factor de forma de red: flujo de aire de la parte delantera a la trasera, puertos frontales, fuentes de alimentación y ventiladores redundantes.</h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/Edge-Platform1405.avif"
                height={350}
              />
            </div>

            <Card.Body>
              <Card.Title className="text-info">
                <h2>Dell Virtual Edge Platform 1405</h2>
              </Card.Title>
              <br />
              <Card.Text>
                <h6>
                Este VEP es un uCPE abierto y compacto de alto valor que está optimizado para alojar VNF y es idóneo para SD-WAN. Perfecto para ubicaciones más pequeñas.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Procesador Intel Atom C-3000 optimizado para redes con bajo consumo de energía.
                </h6>
                <h6>
                  -Dimensiones de sobremesa compactas, con kit disponible para instalaciones en rack.
                </h6>
                <h6>
                    -Disponible validado previamente con las principales plataformas de software de virtualización, como VMware ESXi, Versa y ADVA.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>

      <div className="Puerta-de-enlace-perimetral">
        <Card
          className="servicio text-center text-light "
          bg="black"
          border="light"
        >
          <h1>Puerta de enlace perimetral</h1>
        </Card>
        <CardGroup className="container">
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/Edge-Gateway-5200.avif"
                height={350}
              />
            </div>

            <Card.Body>
              <Card.Title className="text-info">
                <h2>Dell Edge Gateway 5200</h2>
              </Card.Title>
              <br />
              <Card.Text>
                <h6>
                Con Intel® Core™ de 9.ª generación, Dell Edge Gateway 5200 le ayuda a convertir los datos en información procesable con conectividad segura y un rendimiento ininterrumpido, para que pueda aprovechar todo el potencial de los análisis perimetrales.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/Edge-Gateway-3200.avif"
                height={350}
              />
            </div>

            <Card.Body>
              <Card.Title className="text-info">
                <h2>Dell Edge Gateway 3200</h2>
              </Card.Title>
              <br />
              <Card.Text>
                <h6>
                Con un procesador Intel® Atom™, compatibilidad con unidades M.2 y un diseño compacto sin ventilador, Dell Edge Gateway 3200 es una máquina potente in situ, lo que ayuda a mantener el funcionamiento sin problemas incluso en entornos difíciles.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <Footer/>

    </>
  );
};

export default Plataformas;
