import React from "react";
import { Accordion, Card, CardGroup, Figure } from "react-bootstrap";
import Footer from "../../../Layouts/Footer";

const BovedaDatos = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/boveda-toping.webp" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad">
            <h1>Bóveda Protegida de Datos: PowerProtect Cyber Recovery</h1>
          </Card.Title>
          <br />

          <Card.Text className="subtitulo-seguridad">
            <h5>
              PowerProtect Cyber Recovery protege y aísla los datos críticos del
              ransomware y otras amenazas sofisticadas. El aprendizaje
              automático identifica actividades sospechosas y le permite
              recuperar datos correctos conocidos y reanudar las operaciones
              empresariales normales con confianza.
            </h5>
          </Card.Text>
        </Card.ImgOverlay>
      </Card>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Cyber Recovery le ayuda a proteger sus datos a través de:</h1>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card className="text-center text-white bg-black">
            <Figure className="figuras-container" style={{ marginTop: "2%" }}>
              <h4>Inmutabilidad</h4>

              <Figure.Image
                width={171}
                height={180}
                alt=""
                src="./img/Inmotavilidad-boveda-datos.svg"
                style={{ marginTop: "1%" }}
              />
              <Figure.Caption>
                <h6 className="text-white" style={{ marginTop: "1%" }}>
                  Conserve la integridad y la confidencialidad de los datos con
                  varias capas de seguridad y controles
                </h6>
              </Figure.Caption>
            </Figure>

            <Figure className="figuras-container">
              <h4 style={{ marginTop: "1%" }}>Aislamiento</h4>

              <Figure.Image
                width={171}
                height={180}
                alt=""
                src="./img/aislamiento-boveda.svg"
                style={{ marginTop: "1%" }}
              />
              <Figure.Caption>
                <h6 className="text-white" style={{ marginTop: "1%" }}>
                  Separación física y lógica de datos dentro de un vault
                </h6>
              </Figure.Caption>
            </Figure>

            <Figure className="figuras-container" style={{ marginTop: "1%" }}>
              <h4 style={{ marginTop: "1%" }}>Inteligencia</h4>

              <Figure.Image
                width={171}
                height={180}
                alt=""
                src="./img/inteligencia-boveda.svg"
                style={{ marginTop: "2%" }}
              />
              <Figure.Caption>
                <h6 style={{ marginTop: "1%" }} className="text-white">
                  El aprendizaje automático y los análisis aseguran la capacidad
                  de recuperación
                </h6>
              </Figure.Caption>
            </Figure>
          </Card>
        </CardGroup>

        <div style={{ marginTop: "1%" }}>
          <Card className=" text-center text-light " bg="black" border="light">
            <h1>
              Protección y recuperación modernas para datos críticos contra
              ransomware y ciberamenazas
            </h1>
          </Card>
        </div>

        <div className="text-center text-white" style={{ marginTop: "1%" }}>
          <CardGroup>
            <Card className="bg-black" border="white">
              <div className="overflow">
                <Card.Img
                  variant="top"
                  src="img/instalaciones-boveda-datos.avif"
                />
                <Card.ImgOverlay />
              </div>
              <Card.Body>
                <Card.Title className="text-info">
                  En las instalaciones
                </Card.Title>
                <Card.Text>
                  Proporciona el máximo control de los datos y de la
                  infraestructura con un vault seguro en las instalaciones,
                  protegido por una red desconectada operativa y varias capas de
                  seguridad física y lógica.
                </Card.Text>
                <Card.Text>
                  - En las instalaciones, en un vault de Cyber Recovery
                  protegido por una red desconectada.
                </Card.Text>
                <Card.Text>
                  - Inmutabilidad basada en hardware de nivel de cumplimiento
                  normativo y protección contra manipulaciones de NTP.
                </Card.Text>
                <Card.Text>
                  - CyberSense identifica las amenazas y ayuda a garantizar la
                  recuperación.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="bg-black" border="white">
              <div className="overflow">
                <Card.Img variant="top" src="img/housing-boveda-datos.avif" />
                <Card.ImgOverlay />
              </div>
              <Card.Body>
                <Card.Title className="text-info">Coubicación</Card.Title>
                <Card.Text>
                  Amplía nuestra innovadora solución PowerProtect Cyber
                  Recovery, de eficacia probada, en un entorno de cloud seguro
                  para ofrecer a los clientes otra capa de aislamiento para sus
                  datos críticos.
                </Card.Text>
                <Card.Text>
                  - Vault de Cyber Recovery seguro y aislado mediante lógica.
                </Card.Text>
                <Card.Text>
                  - Varias opciones para recuperarse de los ataques y reanudar
                  las operaciones normales de la empresa con confianza.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="bg-black" border="white">
              <div className="overflow">
                <Card.Img
                  variant="top"
                  src="img/cloud-publica-boveda-datos.avif"
                />
                <Card.ImgOverlay />
              </div>
              <Card.Body>
                <Card.Title className="text-info">Nube Pública</Card.Title>
                <Card.Text>
                  Proporciona un vault de cloud pública rápido y fácil de
                  implementar para proteger, aislar y recuperar sistemas y datos
                  críticos de ciberataques.
                </Card.Text>
                <Card.Text>
                  - Vault de Cyber Recovery seguro y aislado mediante lógica.
                </Card.Text>
                <Card.Text>
                  - Varias opciones para recuperarse de los ataques y acelerar
                  la recuperación de los datos con confianza.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </div>
      </div>
      <div>
        <div className="libreria-container-beneficios ">
          <div className="libreria-desc-beneficios">
            <Accordion defaultActiveKey={["0"]}>
              <Accordion.Item eventKey="0" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Aislamiento seguro de los datos</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Los flujos de trabajo automatizados trasladan de forma
                    segura los datos críticos de la empresa a un entorno aislado
                    gracias a un entrehierro operativo. Cree políticas de
                    protección en menos de cinco pasos y supervise las posibles
                    amenazas en tiempo real con un panel intuitivo.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>
                    PowerProtect Cyber Recovery para Sheltered Harbor:
                    protección de instituciones financieras contra riesgos
                    cibernéticos
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    PowerProtect Cyber Recovery es la primera y única solución
                    que recibe la aprobación del estándar de Sheltered Harbor
                    por cumplir todos los requisitos de vaulting de datos, con
                    el fin de proteger las instituciones financieras
                    estadounidenses frente a ciberamenazas como el ransomware.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Recuperación de datos con confianza</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    La respuesta a los ciberataques y la recuperación de datos y
                    sistemas críticos requieren precisión, simplicidad y
                    velocidad. Aumente la resiliencia de su empresa con flujos
                    de trabajo de recuperación automatizados, las herramientas
                    forenses de CyberSense y la respuesta ante incidentes
                    dedicada de SecureWorks.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>
                    Adopte un enfoque integral en su estrategia de Cyber
                    Recovery
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Para recuperarse correctamente de un ciberataque, es
                    fundamental contar con una estrategia alineada con las
                    necesidades empresariales que priorice los procesos más
                    críticos. Con Dell Technologies Services, puede estar
                    tranquilo con respecto a los ciberataques gracias a un
                    programa de recuperación integral que reúne a las personas,
                    los procesos y las tecnologías que necesita para recuperarse
                    con confianza.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>
                    Protección de datos innovadora donde y cuando más la
                    necesita
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    PowerProtect Cyber Recovery protege los datos que impulsan
                    su empresa, aquellos que pueden ser objeto de ciberataques y
                    ransomware. La automatización y la seguridad inteligente
                    aíslan los datos de la superficie objeto de ataque con una
                    red desconectada operativa. Con un almacenamiento inmutable
                    dentro de un vault cibernético dedicado, puede dar respuesta
                    ante amenazas, recuperar datos y retomar las operaciones
                    habituales de la empresa con la confianza de que
                    PowerProtect Cyber Recovery protegerá sus datos y su
                    empresa. El 67 % de las organizaciones no están muy seguras
                    de que puedan recuperar todos los datos críticos de la
                    empresa en caso de un ataque cibernético destructivo. El 62
                    % de los responsables de toma de decisiones de TI están
                    preocupados por que las medidas de protección de datos de su
                    organización no sean suficientes para lidiar con las
                    amenazas de malware y ransomware. El 64 % de las
                    organizaciones están preocupadas por que puedan sufrir una
                    interrupción en los próximos doce meses.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BovedaDatos;
