import { Figure } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, Outlet } from "react-router-dom";
import "./navbar.css";
import { useEffect } from "react";

function BasicExample() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div></div>
      <Navbar
        className="navbar"
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Navbar.Brand className="me-auto container d-flex justify-content-center align-items-center">
              <Figure
                as={Link}
                to="/"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                }}
              >
                <Figure.Image
                  width={161}
                  style={{ marginTop: "5%" }}
                  alt=""
                  src="./img/logo.png"
                />
              </Figure>
            </Navbar.Brand>
            <Nav className="me-auto container d-flex justify-content-center align-items-center">
              <Nav.Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                }}
                as={Link}
                to="/Sobre-Nosotros"
              >
                Sobre Nosotros
              </Nav.Link>
              <NavDropdown title="Productos" id="navbarScrollingDropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/Almacenamiento"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Almacenamientos
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Libreria"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Librerias
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Networking"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Redes y telecomunicaciones
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Productos-de-Oficina"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Oficinas
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Seguridad"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Seguridad
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Servidores"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Servidores
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/Softwares"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Software
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Soluciones" id="navbarScrollingDropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/Premisa"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Premisa{" "}
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/Cloud"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Soluciones Cloud{" "}
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/Proteccion-de-datos"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Soluciones de Protección de Datos{" "}
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/Soluciones-de-seguridad"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Soluciones de Seguridad{" "}
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/Disaster-Recovery"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Disaster Recovery{" "}
                </NavDropdown.Item>


                <NavDropdown.Item
                  as={Link}
                  to="/Continuidad-Operativa"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Continuidad Operativa{" "}
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/Boveda-protegida-de-Datos"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Bóveda Protegida de Datos{" "}
                </NavDropdown.Item>






                <NavDropdown.Item
                  href="https://www.cisco.com/site/us/en/products/security/endpoint-security/secure-endpoint/index.html"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Soluciones End Point{" "}
                </NavDropdown.Item>

                


              </NavDropdown>
              <NavDropdown title="Servicios" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/Asistencia"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Asistencia
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/Capacitaciones"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Entrenamiento
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/Outsourcing"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                >
                  Outsourcing de TI
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                as={Link}
                to="/Soporte"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                }}
              >
                Soporte
              </Nav.Link>
              <Nav.Link
                href="https://sites.google.com/view/hyhsolutions/cotizacion"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                }}
              >
                Cotización
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <section>
        <Outlet></Outlet>
      </section>
    </>
  );
}

export default BasicExample;
