import React from "react";
import { Figure } from "react-bootstrap";
import "./empresa.css";

const Empresa = () => {
  return (
    <>
      <div className="about-container text-center">
        <div className="about-img text-center">
          <Figure>
            <div>
              <Figure.Image
                width={637}
                height={450}
                alt=""
                src="img/AboutPicture.jpg"
                style={{marginTop:"15%"}}
              />
            </div>
          </Figure>
        </div>
        <div className="about-desc">
          <h1> Permítenos decirte</h1>
          <p>
            Los clientes satisfechos siguen día a día confiando en nuestra
            profesionalidad. Desde el primer momento trabajamos en unión con
            nuestros clientes aportando nuestra experiencia en todo el proceso.
          </p>
        </div>
      </div>
    </>
  );
};
export default Empresa;
