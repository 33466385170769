import React from "react";
import { Button, Card, CardGroup } from "react-bootstrap";
import network from "../../../Media/Networking-top.mp4";
import "../Networking/networking.css"

import { Link, Outlet } from "react-router-dom";
import Footer from "../../../Layouts/Footer";
const NetWorking = () => {
  return (
    <>
      <div className="cover-container-networking">
        <video
          className="video-networking"
          src={network}
          autoPlay
          loop
          muted
        ></video>
        <h2>Redes y Telecomunicaciones</h2>
        <h2>_____________________________________________________________</h2>
        <p>
          En H&H Solutions nos hemos caracterizado por anticiparnos a las
          necesidades del mercado, al ofrecer soluciones visionarias de la mano
          de fabricantes internacionales y por ofrecer a nuestros aliados de
          negocios respuestas integradas que vienen a satisfacer determinadas
          necesidades en dichas organizaciones.
        </p>
      </div>
      <Card/>
      

      <div className="card-container text-light text-center">
        <CardGroup>
          <Card
            className="text-light"
            variant="link"
            as={Link}
            to="/Cisco-Network"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
            <Card.Img
              className="bg-black"
              variant="top"
              src="./img/Cisco-network.png"
              
            />

            </div>
            
            
          </Card>

          <Card as={Link} to="/Dell-Network" variant="link">
            <div className="overflow">
            <Card.Img
              className="bg-black "
              variant="top"
              src="./img/Dell-network.png"
           
            />

            </div>
            
            
          </Card>
          <Card
            className="text-black"
            variant="link"
            as={Link}
            to="/Aruba-Network"
          >
            <div className="overflow">
            <Card.Img
              className="bg-black"
              variant="top"
              src="./img/Aruba-network.png"
              
             
            />

            </div>
            
            
          </Card>
        </CardGroup>
      </div>
      <Footer/>
      <section>
        <Outlet></Outlet>
      </section>
    </>
  );
};

export default NetWorking;
