import React from 'react';
import Button from "react-bootstrap/Button";
import Footer from '../../../Layouts/Footer';
function DatosNoEstructurados  ()  {
  return (
    <><div className="datosprimarios-container-PowerMax">
      <div className="datosprimarios-img-PowerMax">
        <img
          variant="top"
          src="./img/powerscale.avif"
          alt="about"
          height={395}
          width={700}
        ></img>
      </div>
      <div className="about-desc-PowerMax">
        <h1> PowerScale</h1>
        <p>Simplicidad a cualquier escala. Gestione cualquier dato en cualquier lugar con información inteligente.</p>
        <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/powerscale.htm#scroll=off&tab0=0">Ver más información</Button>
      </div>
    </div><div className="datosprimarios-container-PowerStore">
        <div className="about-desc-PowerStore">
          <h1> ECS</h1>
          <p>Escala en la nube y almacenamiento de objetos distribuidos geográficamente.</p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/storage/ecs/index.htm#scroll=off&tab0=0&tab1=0">Ver más información</Button>
        </div>
        <div className="datosprimarios-img-PowerStore">
          <img
            variant="top"
            src="./img/PowerStore.avif"
            alt="about"
            height={385}
            width={700}
          ></img>
        </div>
      </div><div className="datosprimarios-container-Unity">
        <div className="datosprimarios-img-Unity">
          <img
            variant="top"
            src="./img/data-streaming-platform.avif"
            alt="about"
            height={435}
            width={700}
          ></img>
        </div>
        <div className="about-desc-Unity">
          <h1> Plataforma de datos de streaming</h1>
          <p>Plataforma de software innovadora que permite analizar y recopilar sus datos de streaming desde el perímetro.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/streaming-data-platform.htm#scroll=off">Ver más información</Button>
        </div>
      </div><div className="datosprimarios-container-PowerVault">
        <div className="about-desc-PowerVault">
          <h1> ObjectScale</h1>
          <p>
          Almacenamiento de objetos nativo de Kubernetes y definido por software para VMware y Red Hat.
          </p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/storage/objectscale.htm#scroll=off&tab0=0">Ver más información</Button>
        </div>
        <div className="datosprimarios-img-PowerVault">
          <img
            variant="top"
            src="./img/objectscale.avif"
            alt="about"
            height={435}
            width={700}
          ></img>
        </div>
      </div>
      <Footer/>

 
      </>
          
   
  );
}

export default DatosNoEstructurados;
