import React, { useState } from "react";
import { Button, Card, Figure, Modal } from "react-bootstrap";
import Footer from "../../../Layouts/Footer";
import "./oficinita2.css";

function Oficinita() {
  const [laptopshow, setShowlaptop] = useState(false);
  const handleCloselaptop = () => setShowlaptop(false);
  const handleShowlaptop = () => setShowlaptop(true);

  const [desktopshow, setShowdesktop] = useState(false);
  const handleClosedesktop = () => setShowdesktop(false);
  const handleShowdesktop = () => setShowdesktop(true);

  const [monitorshow, setShowmonitor] = useState(false);
  const handleClosemonitor = () => setShowmonitor(false);
  const handleShowmonitor = () => setShowmonitor(true);
  return (
    <>
      <div className="container-img-portada">
        <Card className="portada-topic text-white text-center">
          <Card.Img src="img/OficinaPaginaWeb.jpg" alt=""  />

          <Card.ImgOverlay className="portada-main">
            <Card.Title className="titulo">
              <h1>Productos de Oficinas</h1>
            </Card.Title>
          </Card.ImgOverlay>
        </Card>
      </div>
      <>
        <Card
          className="servicio text-center text-light "
          bg="black"
          border="light"
        >
          <h1>Cartera de Productos</h1>
        </Card>
        <div className="Contenedor-imagenes">
          <Figure className="figura-azul" onClick={handleShowlaptop}>
            <div>
              <Figure.Image
                className="capa-imagenes-azul"
                width={337}
                height={280}
                alt=""
                src="img/laptop-oficina.jpg"
              />
            </div>
            <div className="capa-azul">
              <h3>Computadoras Personales</h3>
            </div>
          </Figure>
          {"-"}

          <Modal
            className="text-center"
            show={laptopshow}
            onHide={handleCloselaptop}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Productos Dell</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={250}
                  height={250}
                  alt=""
                  src="./img/latitude-personal-oficina.webp"
                />
                <Figure.Caption>
                  <h4>Latitude</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={250}
                  height={250}
                  alt=""
                  src="./img/Precision-personal-oficina.avif"
                />
                <Figure.Caption>
                  <h4>Precision</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={250}
                  height={250}
                  alt=""
                  src="./img/ruggedlaptop-personal-oficina.avif"
                />
                <Figure.Caption>
                  <h4>Latitude Rugged</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={250}
                  height={200}
                  alt=""
                  src="./img/vostro-personal-oficina.avif"
                />
                <Figure.Caption>
                  <h4>Vostro</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={250}
                  height={200}
                  alt=""
                  src="./img/xps-personal-oficina.avif"
                />
                <Figure.Caption>
                  <h4>XPS</h4>
                </Figure.Caption>
              </Figure>
              <Figure>
                <Figure.Image
                  width={250}
                  height={200}
                  alt=""
                  src="./img/Desktop-oficina.jpg"
                />

                <Figure.Caption className="text-center">
                  <h4>Optiplex</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={180}
                  height={200}
                  alt=""
                  src="./img/precision-tower.avif"
                />

                <Figure.Caption className="text-center">
                  <h4>Precision Tower</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={180}
                  height={200}
                  alt=""
                  src="./img/vostro-tower.avif"
                />

                <Figure.Caption className="text-center">
                  <h4>Vostro Tower</h4>
                </Figure.Caption>
              </Figure>
              <br />
              <br />

              <Figure.Caption>
                <h5>¿Necesita un ordenador?</h5>
                <h6>
                  -En H&H Solutions le ofrecemos el mayor catálogo de
                  ordenadores portátiles y optiplex del mercado. Si buscas
                  versatilidad somos su mejor aliado.
                </h6>
                <br />
                <h5>-Especificaciones:</h5>
                <h6>
                  -Poseemos una cartera de portátiles y optiplex de todo tipo de
                  equipo que solicites. Desde Xps, Latitude, Precision, optiplex
                  y micro optiplex, entre otros. El Límite para obtenerlo eres
                  tú.
                </h6>
              </Figure.Caption>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloselaptop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Figure className="figura-naranja" onClick={handleShowdesktop}>
            <div>
              <Figure.Image
                className="capa-imagenes-naranja"
                width={325}
                height={280}
                alt=""
                src="img/teclado-oficina2.webp"
              />
            </div>

            <div className="capa-naranja">
              <h3>Periféricos</h3>
            </div>
          </Figure>

          <Modal
          className="text-center"
            show={desktopshow}
            onHide={handleClosedesktop}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Periféricos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={250}
                  height={300}
                  alt=""
                  src="./img/Monitores-oficina.jpg"
                />
                <Figure.Caption>
                  <h4>Monitores</h4>
                </Figure.Caption>
              </Figure>
              

              <Figure>
                <Figure.Image
                  width={250}
                  height={300}
                  alt=""
                  src="./img/teclado-mouse.jpg"
                />
                <Figure.Caption>
                  <h4>Mouse y teclados</h4>
                </Figure.Caption>
              </Figure>

              <Figure.Caption>
                <h5>¿No consideras que necesitas uno?</h5>
                <h6>
                  -Solicita con nosotros y equípate con los
                  paquetes completos que tenemos disponibles. H&H Solutions
                  cuenta justo con lo que necesitas para establecerte.
                </h6>
                <h5>-Especificaciones:</h5>
                <h6>¡Ven y solicita con nosotros ya que contamos con una diversidad amplia de teclados, mouses, monitores, entre otros tipos de periféricos que puedes solicitar ya!</h6>
              </Figure.Caption>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleClosedesktop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {"-"}

          <Figure className="figura-gris" onClick={handleShowmonitor}>
            <div>
              <Figure.Image
                className="capa-imagenes-gris"
                width={325}
                height={280}
                alt=""
                src="img/accesorios-oficina.jpg"
              />
            </div>
            <div className="capa-gris">
              <h3>Accesorios</h3>
            </div>
          </Figure>

          <Modal
          className="text-center"
            show={monitorshow}
            onHide={handleClosemonitor}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Accesorios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Figure>
                <Figure.Image
                  width={250}
                  height={300}
                  alt=""
                  src="./img/auriculares.avif"
                />
                <Figure.Caption>
                  <h4>Auriculares</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={250}
                  height={300}
                  alt=""
                  src="./img/docking.webp"
                />
                <Figure.Caption>
                  <h4>Docking Station</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={250}
                  height={300}
                  alt=""
                  src="./img/adaptadores-accesorios.avif"
                />
                <Figure.Caption>
                  <h4>Adaptadores y Cargadores portátiles</h4>
                </Figure.Caption>
              </Figure>

              <Figure>
                <Figure.Image
                  width={250}
                  height={300}
                  alt=""
                  src="./img/boligrafo.webp"
                />
                <Figure.Caption>
                  <h4>Bolígrafos</h4>
                </Figure.Caption>
              </Figure>

              

              <Figure>
                <Figure.Image
                  width={250}
                  height={300}
                  alt=""
                  src="./img/Mochilas.avif"
                />
                <Figure.Caption>
                  <h4>Bolsas de transporte</h4>
                </Figure.Caption>
              </Figure>
              <Figure.Caption>
                  
                  <h5>-Especificaciones:</h5>
                  <h6>-Aproveche al máximo su tiempo con los accesorios diseñados para la comodidad y rendimiento de sus colaboradores. Te ofrecemos, mochilas, auriculares, bocinas, memorias, cámaras, entre otros.</h6>
                  
                </Figure.Caption>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleClosemonitor}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          
        </div>
        <Footer/>
      </>
    </>
  );
}

export default Oficinita;
