import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../../../Layouts/Footer";

const SeguridadUsuarios = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">

        <Card.Img src="img/seguridad-usuario-toping.png" alt="" ></Card.Img>
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad" >
            <h1>Seguridad de Usuarios</h1>
          </Card.Title>
          <br />
        </Card.ImgOverlay>
      </Card>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Productos</h1>
        </Card>
      </div>

      <div
        className="card-container text-light text-center"
        style={{ marginTop: "3%" }}
      >
        <CardGroup>
          <Card
            className="text-light"
            variant="link"
            as={Link}
            to="/Seguridad-de-Cisco-Usuarios"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                className="bg-black"
                variant="top"
                src="./img/Cisco-network.png"
              />
            </div>
          </Card>
          <Card
            className="text-black"
            variant="link"
            as={Link}
            to="/Seguridad-de-Kaspersky-Usuarios"
          >
            <div className="overflow">
              <Card.Img
                className="bg-black"
                variant="top"
                src="./img/kaspersky-new.png"
              />
            </div>
          </Card>
        </CardGroup>
      </div>

      <Footer />
    </div>
  );
};

export default SeguridadUsuarios;
