import React from "react";
import { Button, Card, CardGroup } from "react-bootstrap";
import Footer from "../../../../Layouts/Footer";

const SeguridadIndustrial = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/seguridad-industrial-toping.jpg" alt="" />
          <Card.ImgOverlay className="main-seguridad">
            <Card.Title className="titulo-seguridad">
              <h1>Seguridad Industrial</h1>
            </Card.Title>
            <br />
          </Card.ImgOverlay>
        </Card>

      <Card
        className="CiscoBorder text-center text-light "
        bg="black"
        border="light"
      >
        <h1>Cisco</h1>
      </Card>
      <br />

      
      <CardGroup>
        <Card
          className="text-light text-center"
          bg="dark"
          border="light"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>Enforce micro-segmentation policies</h5>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Extend software-based network segmentation policies to your
              industrial control network. Policies are applied dynamically based
              on zones and conduits defined by OT teams.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/site/us/en/products/security/identity-services-engine/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Segment with Cisco Identity Services Engine (ISE)
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-center"
          bg="light"
          border="light"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>Protect industrial workstations from malware</h5>{" "}
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Reduce the attack surface by using advanced endpoint detection and
              response (EDR) powered by Cisco Talos. Find sophisticated attacks
              faster and accelerate remediation to maintain uptime.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/site/us/en/products/security/endpoint-security/secure-endpoint/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Get details on Cisco Secure Endpoint
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-light text-center"
          border="light"
          bg="dark"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>Secure remote access to industrial networks and devices</h5>{" "}
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Empower OT teams to perform critical day-to-day operations on
              remote or distributed industrial equipment easily and securely.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/site/us/en/products/security/secure-client/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Secure VPN with Cisco AnyConnect
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-black text-center"
          border="light"
          bg="light"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>Use zero-trust authentication for users and devices</h5>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Verify user identity and device security for access to industrial
              networks, using Cisco Secure Access by Duo adaptive multi-factor
              authentication.
            </Card.Text>
            <br />
            <Button
              href="https://duo.com/?utm_source=ciscoIoTsecurity&utm_medium=referral#eyJoYXNoIjoiIiwic2VhcmNoIjoiP3V0bV9zb3VyY2U9Y2lzY29Jb1RzZWN1cml0eSZ1dG1fbWVkaXVtPXJlZmVycmFsIn0="
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Get to know Duo
            </Button>
          </Card.Body>
        </Card>
        <br />

        <br />
      </CardGroup>
      <Footer/>
      
    </div>
    
  );
};

export default SeguridadIndustrial;
