import React, { useState } from "react";
import { Button, Card, Figure, Modal } from "react-bootstrap";
import "./oficinita.css";
function Oficinas() {
  const [laptopshow, setShowlaptop] = useState(false);
  const handleCloselaptop = () => setShowlaptop(false);
  const handleShowlaptop = () => setShowlaptop(true);

  const [desktopshow, setShowdesktop] = useState(false);
  const handleClosedesktop = () => setShowdesktop(false);
  const handleShowdesktop = () => setShowdesktop(true);

  const [monitorshow, setShowmonitor] = useState(false);
  const handleClosemonitor = () => setShowmonitor(false);
  const handleShowmonitor = () => setShowmonitor(true);

  const [impresorashow, setShowimpresora] = useState(false);
  const handleCloseimpresora = () => setShowimpresora(false);
  const handleShowimpresora = () => setShowimpresora(true);

  const [estacionshow, setShowestacion] = useState(false);
  const handleCloseestacion = () => setShowestacion(false);
  const handleShowestacion = () => setShowestacion(true);

  const [mouseshow, setShowmouse] = useState(false);
  const handleClosemouse = () => setShowmouse(false);
  const handleShowmouse = () => setShowmouse(true);

  const [tecladoshow, setShowteclado] = useState(false);
  const handleCloseteclado = () => setShowteclado(false);
  const handleShowteclado = () => setShowteclado(true);

  const [tabletshow, setShowtablet] = useState(false);
  const handleClosetablet = () => setShowtablet(false);
  const handleShowtablet = () => setShowtablet(true);

  const [accesorioshow, setShowaccesorio] = useState(false);
  const handleCloseaccesorio = () => setShowaccesorio(false);
  const handleShowaccesorio = () => setShowaccesorio(true);

  const [USBshow, setShowUSB] = useState(false);
  const handleCloseUSB = () => setShowUSB(false);
  const handleShowUSB = () => setShowUSB(true);

  const [Discoshow, setShowDisco] = useState(false);
  const handleCloseDisco = () => setShowDisco(false);
  const handleShowDisco = () => setShowDisco(true);

  const [VGAshow, setShowVGA] = useState(false);
  const handleCloseVGA = () => setShowVGA(false);
  const handleShowVGA = () => setShowVGA(true);

  const [Ramshow, setShowRam] = useState(false);
  const handleCloseRam = () => setShowRam(false);
  const handleShowRam = () => setShowRam(true);

  const [proyectorshow, setShowproyector] = useState(false);
  const handleCloseproyector = () => setShowproyector(false);
  const handleShowproyector = () => setShowproyector(true);

  const [Otroshow, setShowOtro] = useState(false);
  const handleCloseOtro = () => setShowOtro(false);
  const handleShowOtro = () => setShowOtro(true);

  
  return (
    <>
      <div className="container-img-portada">
        <Card className="portada text-white text-center">
          <Card.Img src="img/Oficina-toping.jpg" alt="" height={850} />

          <Card.ImgOverlay className="portada-main">
            <Card.Title className="titulo">
              <h1>Soluciones de Oficinas</h1>
            </Card.Title>
          </Card.ImgOverlay>
        </Card>
      </div>
      <br />
      <div className="Contenedor-imagenes">
        <Figure className="figura-azul" onClick={handleShowlaptop}>
          <div>
            <Figure.Image
              className="capa-imagenes-azul"
              width={337}
              height={280}
              alt=""
              src="img/laptop-oficina.jpg"
            />
          </div>
          <div className="capa-azul">
            <h3>Portátiles</h3>
            
          </div> 
        </Figure>
        {"-"}

        <Modal
            show={laptopshow}
            onHide={handleCloselaptop}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Portátiles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={200}
                  alt=""
                  src="./img/laptop-oficina2.jpg"
                  className="container"
                />
                <Figure.Caption>
                  <h5>¿Necesita un ordenador portátil?</h5>
                  <h6>-En H&H Solutions le ofrecemos el mayor catálogo de ordenadores portátiles del mercado. Si buscas versatilidad somos su mejor aliado.</h6>
                  <br/>
                  <h5>-Especificaciones:</h5>
                  <h6>
                    Poseemos una cartera de portátiles de todo tipo de equipo que solicites, especialmente equipos Dell, Lenovo y HP. El Límite para obtenerlo eres tú.
                  </h6>
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloselaptop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

        <Figure className="figura-naranja" onClick={handleShowdesktop}>
          <div>
            <Figure.Image
              className="capa-imagenes-naranja"
              width={325}
              height={280}
              alt=""
              src="img/Desktop-oficina2.webp"
            />
          </div>

          <div className="capa-naranja">
            <h3>Desktop</h3>
            
          </div>
        </Figure>

        <Modal
            show={desktopshow}
            onHide={handleClosedesktop}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Desktop</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/Desktop-oficina.jpg"
                  className="container"
                />
                <Figure.Caption>
                  <h5>¿No consideras que necesitas uno? </h5>
                  <h6>-Solicita tu ordenador con nosotros y equípate con los paquetes completos que tenemos disponibles. H&H Solutions cuenta justo con lo que necesitas para establecerte.</h6>
                  
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleClosedesktop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-gris" onClick={handleShowmonitor}>
          <div>
            <Figure.Image
              className="capa-imagenes-gris"
              width={325}
              height={280}
              alt=""
              src="img/Monitores-oficina.jpg"
            />
          </div>
          <div className="capa-gris">
            <h3>Monitores</h3>
           
          </div>
        </Figure>

        <Modal
            show={monitorshow}
            onHide={handleClosemonitor}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Monitores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/Monitores-oficina2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>-Optimice su espacio visual de trabajo.</h5>
                  <h6>Mantenga la productividad y eficiencia en su lugar de trabajo con los monitores de ultima generación.</h6>
                  <br/>
                  <h5>-Especificaciones:</h5>
                  <h6>
                    Poseemos Monitores de todo tipo, especialmente Dell. Cuenta con nosotros.
                 
                  </h6>
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleClosemonitor}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

        {"-"}



        <Figure className="figura-verde" onClick={handleShowimpresora}>
          <div>
            <Figure.Image
              className="capa-imagenes-verde"
              width={365}
              height={290}
              alt=""
              src="img/Impresoras-oficina.jpg"
            />
          </div>
          <div className="capa-verde">
            <h3>impresora</h3>
            
          </div>
        </Figure>

        <Modal
            show={impresorashow}
            onHide={handleCloseimpresora}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Impresoras</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/Impresoras-oficina2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>Impresoras multifuncionales para su oficina.</h5>
                  <h6>-Ahorre tiempo y dinero en sus trabajos, con la gama de impresoras completamente adaptadas a su necesidad.</h6>
                  <h5>-Especificaciones:</h5>
                  <h6>
                    Poseemos impresoras láser color e impresora láser B/W, Scanners y ploters.
                  </h6>
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseimpresora}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-azul" onClick={handleShowestacion}>
          <div>
            <Figure.Image
              className="capa-imagenes-azul"
              width={365}
              height={270}
              alt=""
              src="img/Workstation2.jpg"
            />
          </div>
          <div className="capa-azul">
            <h3>Estación de trabajo</h3>
            
          </div>
        </Figure>

        <Modal
            show={estacionshow}
            onHide={handleCloseestacion}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Estación de trabajo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/Workstation3.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>-Facilita a tu equipo y expándete con nosotros con fines de ampliar tu estación de trabajo </h5>
                  
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseestacion}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-naranja" onClick={handleShowmouse}>
          <div>
            <Figure.Image
              className="capa-imagenes-naranja"
              width={365}
              height={270}
              alt=""
              src="img/mouse-oficina.jpg"
            />
          </div>
          <div className="capa-naranja">
            <h3>Mouse</h3>
            
          </div>
        </Figure>

        <Modal
            show={mouseshow}
            onHide={handleClosemouse}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Mouse</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/mouse-oficina2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>Navegue con versatilidad y comodidad</h5>
                  <h6>-Navegue de forma fácil y rápida con las opciones de mouses que disponemos para sus equipos de oficina.</h6>
                  <h5>-Especificaciones:</h5>
                  <h6>
                    Ofrecemos mouse alámbricos e inalámbricos originales de cualquiera que necesites.
                  </h6>
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleClosemouse}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}
 
        <Figure className="figura-gris" onClick={handleShowteclado}>
          <div>
            <Figure.Image
              className="capa-imagenes-gris"
              width={365}
              height={270}
              alt=""
              src="img/teclado-oficina.jpg"
            />
          </div>
          <div className="capa-gris">
            <h3>Teclados</h3>
           
          </div>
        </Figure>

        <Modal
            show={tecladoshow}
            onHide={handleCloseteclado}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Teclados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/teclado-oficina2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>Explore los mejores teclados y trabaje con más tiempo y mayor rendimiento.</h5>
                  <br/>
                  <h5>-Especificaciones:</h5>
                  <h6>
                  Ofrecemos teclados alámbricos e inalámbricos originales de cualquiera que necesites.
                  </h6>
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseteclado}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-verde" onClick={handleShowtablet}>
          <div>
            <Figure.Image
              className="capa-imagenes-verde"
              width={365}
              height={270}
              alt=""
              src="img/tablet-oficina.jpg"
            />
          </div>
          <div className="capa-verde">
            <h3>Tablets</h3>
            
          </div>
        </Figure>

        <Modal
            show={tabletshow}
            onHide={handleClosetablet}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Tablets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/tablets-oficina2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>-Obtenga la tablet de su preferencia con nosotros. H&H Solutions cuenta con una cartera de tablets disponible para ti.</h5>
                  
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleClosetablet}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}
        <Figure className="figura-azul" onClick={handleShowaccesorio}>
          <div>
            <Figure.Image
              className="capa-imagenes-azul"
              width={365}
              height={270}
              alt=""
              src="img/accesorios.png"
            />
          </div>
          <div className="capa-azul">
            <h3>Accesorios</h3>
            
          </div>
        </Figure>

        <Modal
            show={accesorioshow}
            onHide={handleCloseaccesorio}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Accesorios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/accesorios2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>Aproveche al máximo su tiempo con los accesorios diseñados para la comodidad y rendimiento de sus colaboradores.</h5>
                  <h5>-Especificaciones:</h5>
                  <h6>Te ofrecemos, mochilas, auriculares, bocinas, memorias, cámaras, entre otros.</h6>
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseaccesorio}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-naranja" onClick={handleShowUSB}>
          <div>
            <Figure.Image
              className="capa-imagenes-naranja"
              width={365}
              height={270}
              alt=""
              src="img/Usb.jpg"
            />
          </div>
          <div className="capa-naranja">
            <h3>Memorias Usb</h3>
            
          </div>
        </Figure>

        <Modal
            show={USBshow}
            onHide={handleCloseUSB}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Memorias USB</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/Usb2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>-Obtenga con nosotros su memoria USB justo a su medida.</h5>
                  
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseUSB}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-gris" onClick={handleShowDisco}>
          <div>
            <Figure.Image
              className="capa-imagenes-gris"
              width={365}
              height={270}
              alt=""
              src="img/DiscoDuro.jpg"
            />
          </div>
          <div className="capa-gris">
            <h3>Discos</h3>
            
          </div>
        </Figure>

        <Modal
            show={Discoshow}
            onHide={handleCloseDisco}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Disco Duro</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/DiscoDuro-oficina2.jpg"
                  className="container"
                />
                <Figure.Caption>
                  <h5>Catálogos de Discos Duros</h5>
                  <h6>Almacene sus datos durante largos periodos de tiempo de manera individual o múltiple</h6>
                  <h5>-Especificaciones:</h5>
                  <h6>
                    Poseemos para fines tu uso los HDD, SSD, SSD externos y cualquier tipo de disco que necesites.
                  </h6>
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseDisco}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-verde" onClick={handleShowVGA}>
          <div>
            <Figure.Image
              className="capa-imagenes-verde"
              width={365}
              height={270}
              alt=""
              src="img/VGA.jpeg"
            />
          </div>
          <div className="capa-verde">
            <h3>Adaptadores VGA</h3>
            
          </div>
        </Figure>

        <Modal
            show={VGAshow}
            onHide={handleCloseVGA}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Adaptador VGA</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/vga2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>Adaptadores de pantalla</h5>
                  <h6>-Convierta su computador portátil en un compañero de utilidad. Obtenga los puertos que necesita para el mayor rendimiento y destreza de sus equipos tecnologicos.</h6>
                 
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseVGA}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-azul" onClick={handleShowRam}>
          <div>
            <Figure.Image
              className="capa-imagenes-azul"
              width={365}
              height={270}
              alt=""
              src="img/MemoriaRAM.jpg"
            />
          </div>
          <div className="capa-azul">
            <h3>Memorias Ram</h3>
            
          </div>
        </Figure>

        <Modal
            show={Ramshow}
            onHide={handleCloseRam}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Memorias Ram</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/Ram2.jpeg"
                  className="container"
                />
                <Figure.Caption>
                  <h5>Te ofrecemos la Ram que necesitas para mejor optimización de su equipo</h5>
                  <h5>-Especificaciones:</h5>
                  <h6>
                    Memorias Ram para el alcance de tus servidores, ordenadores y pórtatiles para mejor rendimiento de tus equipos.
                  </h6>
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseRam}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}

        <Figure className="figura-naranja" onClick={handleShowproyector}>
          <div>
            <Figure.Image
              className="capa-imagenes-naranja"
              width={365}
              height={270}
              alt=""
              src="img/proyector-oficina2.webp"
            />
          </div>
          <div className="capa-naranja">
            <h3>Proyectores</h3>
            
          </div>
        </Figure>

        <Modal
            show={proyectorshow}
            onHide={handleCloseproyector}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Proyectores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/proyector.jpg"
                  className="container"
                />
                <Figure.Caption>
                  <h5>Proyectores para trabajo</h5>
                  <h6>-En H&H Solutions le ofrecemos los proyectores ideales con un precio óptimo para todas las oficinas.</h6>

                  
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseproyector}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}
        <Figure className="figura-gris" onClick={handleShowOtro}>
          <div>
            <Figure.Image
              className="capa-imagenes-gris"
              width={365}
              height={270}
              alt=""
              src="img/entre-otros.jpg"
            />
          </div>
          <div className="capa-gris">
            <h3>Otros</h3>
            
          </div>
        </Figure>

        <Modal
            show={Otroshow}
            onHide={handleCloseOtro}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Image
                  width={1}
                  height={300}
                  alt=""
                  src="./img/otros-oficina2.webp"
                  className="container"
                />
                <Figure.Caption>
                  <h5>En H&H Solutions contamos con un catálogo ampliamente diseñado a las exigencias del mercado tecnológico y a las necesidades de sus colaboradores.</h5>
                  <br/>
                  <h5>-Especificaciones:</h5>
                  <h6>
                    Contamos con cartuchos, tarjetas ethernet, HBA, Monturas, UPS, ATS, entre otros.
                  </h6>
                  
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseOtro}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        {"-"}
      </div>
    </>
  );
}

export default Oficinas;
