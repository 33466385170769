import React from 'react';
import { Button, Card, CardGroup } from 'react-bootstrap';

const KasperskyNube = () => {
  return (
    <div>
      <CardGroup>
        <Card
          className="container text-light text-center"
          border="light"
          bg="dark"
          style={{ width: "68rem", height: "25rem", marginTop:"5%" }}
        >
          <Card.Header className="text-center">
            <h5>Kaspersky Hybrid Cloud Security</h5>
          </Card.Header>

          <Card.Body>
            <Card.Title>
              <h5>Seguridad inmediata para su experiencia en la nube.</h5>
            </Card.Title>
            <br />
            <Card.Text>
              * Protección de la nube inmediata y automatizada.
            </Card.Text>
            <Card.Text>
              * Disfrute de una protección integral, desde el primer momento.
            </Card.Text>
            <Card.Text>
              * Administre todas sus cargas de trabajo desde una única consola
              en las instalaciones o en la nube.
            </Card.Text>
            * Implemente directamente desde los mercados o como un servicio.
            <Card.Text>
              <Card.Text>
                * Elija la opción más rentable para su empresa con nuestro
                modelo de licencia flexible.
              </Card.Text>
            </Card.Text>
            <br />
            <Button
              href="https://latam.kaspersky.com/small-to-medium-business-security/cloud-security"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explore Kaspersky Hybrid Cloud Security
            </Button>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}

export default KasperskyNube;
