import React from "react";
import { Card, CardGroup, Figure } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Footer from "../../../../Layouts/Footer";
import "../networking.css";

const Dellnetwork = () => {
  return (
    <>
      <div className="container-img-portada">
        <Card className="portada text-white text-center">
          <Card.Img
            src="img/Foto1PaginaWeb.png"
            alt=""
            
          />

          <Card.ImgOverlay>
            <div className="about-portada">
              <h1>Modernice su red con equipos de Dell Technologies</h1>
            </div>

            <br />
          </Card.ImgOverlay>
        </Card>
      </div>
      <br />
      <div>
        <Card className="text-center text-white bg-black">
          <Figure className="figuras-container">
            <h4>Perimetro</h4>

            <Figure.Image
              width={171}
              height={180}
              alt=""
              src="./img/PerimetroTop-DellNetworking.svg"
            />
            <Figure.Caption>
              <h6 className="text-white">
                Maximice la conectividad en el perímetro con soluciones
                integradas de hardware y software para SD-WAN y otras funciones
                complementarias de redes virtualizadas.
              </h6>
            </Figure.Caption>
          </Figure>
          <br />
          <Figure className="figuras-container">
            <h4>Core</h4>

            <Figure.Image
              width={171}
              height={180}
              alt=""
              src="./img/CoreTop-DellNetworking.svg"
            />
            <Figure.Caption>
              <h6 className="text-white">
                Integre soluciones de conmutación de centros de datos que
                satisfagan las exigencias de las cargas de trabajo y los
                entornos de virtualización modernos, al tiempo que simplifican
                considerablemente las implementaciones y la gestión.
              </h6>
            </Figure.Caption>
          </Figure>
          <Figure className="figuras-container">
            <h4>Cloud</h4>

            <Figure.Image
              width={171}
              height={180}
              alt=""
              src="./img/CloudTop-DellNetworking.svg"
            />
            <Figure.Caption>
              <h6 className="text-white">
                Impulse las implementaciones de cloud con soluciones PowerSwitch
                integradas en soluciones de HCI y que proporcionen
                automatización de la configuración de red y ahorros en gastos de
                explotación.
              </h6>
            </Figure.Caption>
          </Figure>
        </Card>
      </div>
      <br />

      <div className="bg-black">
        <CardGroup className="container bg-black">
          <Card
            className="bg-black"
            border="light"
            as={Link}
            to="/Switches-de-Ethernet"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/SwitchesToping.jpg"
                height={350}
              />
            </div>

            <Card.Body className="bg-black text-center text-white">
              <Card.Title className="text-info">
                Switches de Ethernet
              </Card.Title>
              <Card.Text>
                Haga el cambio donde lo necesita, del perímetro al núcleo y a la
                cloud. Diseñada para una arquitectura ágil y flexible para
                ayudar a los centros de datos a migrar con fluidez a un centro
                de datos diseñado por software.
              </Card.Text>
              <Button
                variant="outline-info"
                as={Link}
                to="/Switches-de-Ethernet"
                style={{ textDecoration: "none" }}
              >
                Explore
              </Button>
            </Card.Body>
          </Card>
          <Card
            className="bg-black"
            border="light"
            as={Link}
            to="/Plataformas-de-Perimetro"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/SwitchPerimetral.webp"
                height={350}
              />
            </div>

            <Card.Body className="bg-black text-center text-white">
              <Card.Title className="text-info">
                Plataformas de perímetro
              </Card.Title>
              <Card.Text>
                Modernice su infraestructura de área ancha con las plataformas
                de hardware y VNF (funciones de red virtual) líderes del sector.
              </Card.Text>
              <Button
                variant="outline-info"
                as={Link}
                to="/Plataformas-de-Perimetro"
                style={{ textDecoration: "none" }}
              >
                Explore
              </Button>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <Footer/>

    </>
  );
};

export default Dellnetwork;
