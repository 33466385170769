import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../../Layouts/Footer";

const SolucionSeguridad = () => {
  return (
    <>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/soluciones-seguridad-toping.png" alt="" />
          <Card.ImgOverlay className="main-seguridad">
            <Card.Title className="titulo-seguridad">
              <h1>Seguridad</h1>
            </Card.Title>
            <br />

            <Card.Text className="subtitulo-seguridad">
              <h5>
                En H&H Solutions nos hemos caracterizado por anticiparnos a las
                necesidades del mercado local, al ofrecer soluciones visionarias
                de la mano de fabricantes internacionales y por ofrecer a
                nuestros aliados de negocios respuestas integradas que vienen a
                satisfacer determinadas necesidades en dichas organizaciones.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <div
        className="card-container-almacenamiento text-center  text-light"
        style={{ marginTop: "1%" }}
      >
        <CardGroup>
          <Card>
            <Card.Img
              variant="top"
              src="./img/Seguridad-app.webp"
              className="bg-black"
            />
            <Card.ImgOverlay
              className="capa-seguridad"
              as={Link}
              to="/Seguridad-de-Nubes-y-aplicaciones"
              style={{ textDecoration: "none" }}
            >
              <Card.Title className="text-light">
                <h3>Seguridad de aplicacion</h3>
              </Card.Title>
            </Card.ImgOverlay>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src="./img/seguridad-perimetro.webp"
              className="bg-black"
            />
            <Card.ImgOverlay
              className="capa-seguridad"
              as={Link}
              to="/Seguridad-de-Redes"
              style={{ textDecoration: "none" }}
            >
              <Card.Title className="text-light">
                <h3>Seguridad en Infraestructura</h3>
              </Card.Title>
            </Card.ImgOverlay>
          </Card>
        </CardGroup>

        <CardGroup>
        <Card>
            <Card.Img
              variant="top"
              src="./img/perimetral-seguridad-solucion.webp"
              className="bg-black"
            />
            <Card.ImgOverlay
              style={{ textDecoration: "none", cursor:"pointer" }}
            >
              <Card.Title className="text-light">
                <h3>Seguridad Perimetral</h3>
              </Card.Title>
            </Card.ImgOverlay>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src="./img/seguridad-usuario-final.webp"
              className="bg-black"
            />
            <Card.ImgOverlay
              className="capa-seguridad"
              as={Link}
              to="/Seguridad-de-Usuarios-y-dispositivos"
              style={{ textDecoration: "none" }}
            >
              <Card.Title className="text-light">
                <h3>Seguridad de Usuario Final</h3>
              </Card.Title>
            </Card.ImgOverlay>
          </Card>
        </CardGroup>
      </div>
      <Footer />
    </>
  );
};

export default SolucionSeguridad;
