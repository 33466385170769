import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import Footer from "../../../Layouts/Footer";

const ServidoresDell = () => {
  return (
    <>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/servidorTop-Dell.jpg" alt="" />
          <Card.ImgOverlay className="main-seguridad" >
            <Card.Title className="titulo-seguridad" style={{ marginTop: "20%" }}>
              <h1>Servidores</h1>
            </Card.Title>
            <br />

            <Card.Text className="subtitulo-seguridad">
              <h5>
                Acelere su transformación digital con una amplia cartera de
                servidores optimizados para las últimas cargas de trabajo.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>

        <div style={{ marginTop: "1%" }}>
          <Card className=" text-center text-light " bg="black" border="light">
            <h1>Productos</h1>
          </Card>
        </div>
      </div>
      <div className="bg-black" style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card className="bg-light text-center text-light" border="secondary">
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="./img/rack-server.avif" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                <h2>Rack Server</h2>
              </Card.Title>

              <Card.Text>
                <h6>
                  Deliver secure, scalable infrastructure with high density one,
                  two or four-socket rack servers that are optimized to power
                  your systems to work together and independently. Ideal for
                  traditional applications, virtualization and cloud-native
                  workloads.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-light text-center text-light" border="secondary">
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="./img/tower-server.webp" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                <h2>Tower Server</h2>
              </Card.Title>

              <Card.Text>
                <h6>
                  Leverage a range of compact and quiet tower servers including
                  one-socket configurations that are ideal for everyday business
                  applications, and two-socket options for more advanced
                  computing, virtualization, and power-hungry workloads.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="bg-light text-center text-light" border="secondary">
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="./img/modular-server.png" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                <h2>Modular Infrastructure</h2>
              </Card.Title>

              <Card.Text>
                <h6>
                  Easily tailor, deploy, and manage your infrastructure while
                  lowering operating costs. Create your future-ready data center
                  with compute, networking, and storage modules that can be
                  precisely tailored to your needs and expanded over time.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <br />
        <CardGroup>
          <Card className="bg-light text-center text-light" border="secondary">
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="./img/rugged-server.avif" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                <h2>Rugged Servers</h2>
              </Card.Title>

              <Card.Text>
                <h6>
                  Deliver customized, high-performance compute with enterprise
                  edge servers certified for telco and military use and built to
                  withstand extreme heat, dust, shock and vibration.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="bg-light text-center text-light" border="secondary">
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="./img/specialty-server.png" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                <h2>Specialty Servers</h2>
              </Card.Title>

              <Card.Text>
                <h6>
                  Deliver reliable and secure high-performance applications and
                  accelerate compute-intensive workloads inside traditional data
                  centers or in extreme conditions at the edge.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <Footer />
      </div>
    </>
  );
};

export default ServidoresDell;
