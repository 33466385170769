import React from "react";
import { Button, Card, CardGroup } from "react-bootstrap";
import Footer from "../../../../Layouts/Footer";

const SolarwindsNetwork = () => {
  return (
    <>
      <div className="container-img-portada-cisco">
        <Card className="portada-cisco text-white text-center" border="white">
          <Card.Img src="img/Prueba-aruba-toping.jpg" alt="" />

          <Card.ImgOverlay>
            <div className="about-portada-cisco">
              <h1>Configure su red con Aruba</h1>
            </div>

            <br />
          </Card.ImgOverlay>
        </Card>
      </div>

      <div>
        <Card
          className="servicio text-center text-light "
          bg="black"
          border="light"
        >
          <h1>Portafolio de switches</h1>
        </Card>
      </div>

      <div>
        <CardGroup className="container">
          <Card className="bg-black text-center text-light" border="white">
            <div className="overflow">
              <a href="https://www.arubanetworks.com/products/switches/">
                <Card.Img
                  variant="top"
                  src="img/toping1-network-aruba.webp"
                  height={300}
                />
              </a>
            </div>
            <Card.Body>
              <Card.Title className="text-secondary">Vision general</Card.Title>
              <Card.Text>
                Descubra una cartera de conmutación diseñada específicamente
                para la nube, dispositivos móviles e IoT.
              </Card.Text>
              <Button
                variant="outline-light"
                href="https://www.arubanetworks.com/products/switches/"
                style={{ textDecoration: "none" }}
              >Explore</Button>
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-light" border="white">
            <div className="overflow">
              <a href="https://www.arubanetworks.com/products/switches/access/">
                <Card.Img
                  variant="top"
                  src="img/toping2-network-aruba.jpg"
                  height={300}
                />
              </a>
            </div>

            <Card.Body>
              <Card.Title className="text-secondary">
                Switches de acceso
              </Card.Title>
              <Card.Text>
                Un Edge más inteligente y seguro está más cerca de lo que cree.
                Obtenga lo que necesita para conectar usuarios, puntos de acceso
                Wi-Fi 6 y dispositivos IoT. Encuentre soluciones que escalan con
                su negocio, soluciones que se pueden implementar y administrar
                con facilidad.
              </Card.Text>
              <Button
                variant="outline-light"
                href="https://www.arubanetworks.com/products/switches/access/"
                style={{ textDecoration: "none" }}
              >Explore</Button>
            </Card.Body>
          </Card>
        </CardGroup>

        <CardGroup className="container">
          <Card className="bg-black text-center text-light" border="white">
            <div className="overflow">
              <a href="https://www.arubanetworks.com/products/switches/core-and-data-center/">
                <Card.Img
                  variant="top"
                  src="img/toping3-menu-aruba.jpg"
                  height={300}
                />
              </a>
            </div>
            <Card.Body>
              <Card.Title className="text-secondary">
                Switches de campus y de centro de datos
              </Card.Title>
              <Card.Text>
                Satisfaga las necesidades de su centro de campus y requisitos de
                redes de centros de datos en constante evolución. Simplifique
                las operaciones con automatización inteligente, análisis
                distribuido e infraestructura siempre activa.
              </Card.Text>
              <Button
                variant="outline-light"
                href="https://www.arubanetworks.com/products/switches/core-and-data-center/"
                style={{ textDecoration: "none" }}
              >Explore</Button>
            </Card.Body>
          </Card>

          <Card className="bg-black text-center text-light" border="white">
            <div className="overflow">
              <a href="https://www.arubanetworks.com/products/switches/distributed-network-analytics/">
                <Card.Img
                  variant="top"
                  src="img/toping-menu-aruba.jpg"
                  height={300}
                />
              </a>
            </div>

            <Card.Body>
              <Card.Title className="text-secondary">
                Motor de análisis de redes
              </Card.Title>
              <Card.Text>
                Aruba Network Analytics Engine (NAE), integrado en determinados
                switches Aruba CX, proporciona información en tiempo real de
                toda la red para acelerar la resolución de problemas y la
                planificación.
              </Card.Text>
              <Button
                variant="outline-light"
                href="https://www.arubanetworks.com/products/switches/distributed-network-analytics/"
                style={{ textDecoration: "none" }}
              >Explore</Button>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <Footer/>
    </>
  );
};

export default SolarwindsNetwork;
