import React from "react";
import { Card, CardGroup, Figure } from "react-bootstrap";
import Footer from "../../Layouts/Footer";

const Marcas = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/relaciones-toping.avif" alt="" />
          <Card.ImgOverlay className="main-software">
            <Card.Title style={{marginTop:"-3%"}} className="titulo-software">
              <h1>Alianzas</h1>
            </Card.Title>

            <Card.Text className="subtitulo-software">
              <h5>
               Conozca a nuestros aliados estratégicos enfocados en aportar soluciones tecnológicas para la mayor eficiencia para todos.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>

        

        <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card className="bg-black" border="white">
            <div className="overflow">
                
              <Card.Img variant="top" src="img/Dell-network.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/Cisco-network.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/Veeam-Prueba.jpg" />
              <Card.ImgOverlay/>
            </div>
          </Card>
        </CardGroup>



        <CardGroup>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/quantum-logo2.jpg" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/VMware_logo-software.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/hp-logo3.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              {" "}
              <Card.Img variant="top" src="./img/nuevoHPE.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
        </CardGroup>


        

        <CardGroup>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/oracle-logo.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/SolarWinds-software.jpg" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/Microsoft-Logo.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              {" "}
              <Card.Img variant="top" src="img/kaspersky-new.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
        </CardGroup>
      </div>
      <Footer/>
    </div>
  );
};
export default Marcas;
