import React from "react";
import { Accordion, Card, CardGroup, Figure } from "react-bootstrap";
import Footer from "../../../Layouts/Footer";

const Recovery = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/DisasterRecovery-toping.jpg" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad" style={{ marginTop: "14%" }}>
            <h1>Disaster Recovery</h1>
          </Card.Title>
        </Card.ImgOverlay>
      </Card>

      <div className="libreria-container" style={{ marginTop: "1%" }}>
        <div className="libreria-desc">
          <h1>Recuperación y replicación de datos con Data Protection Suite</h1>

          <p>
            Dell EMC Data Protection Suite es una solución de respaldo y
            recuperación integral para organizaciones de todos los tipos y
            tamaños.
          </p>
        </div>
        <div>
          <Figure>
            <Figure.Image
              variant="top"
              className="rounded"
              width={1500}
              height={500}
              alt="171x180"
              src="/img/disasterRecovery-sub.avif"
              style={{ marginTop: "9%", marginLeft: "-6%" }}
            />
          </Figure>
        </div>
      </div>

      <div style={{ marginTop: "1%"}} className="text-white text-center">
        <CardGroup>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/recovery1.avif" />
              <Card.ImgOverlay/>
            </div>

            <Card.Body>
              <Card.Title className="text-secondary">Respalde datos con Dell EMC Integrated Data Protection Appliance</Card.Title>
              <Card.Text>
              Dell EMC Integrated Data Protection Appliance (IDPA) ofrece respaldo, replicación y recuperación de datos completos, todo en uno.
              </Card.Text>
            </Card.Body>
           
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/recovery2.avif" />
              <Card.ImgOverlay/>
            </div>

            <Card.Body>
              <Card.Title className="text-secondary">Explore la protección de datos de Dell EMC</Card.Title>
              <Card.Text>
              Proteja el valor comercial de sus datos con soluciones de protección de datos líderes en la industria de Dell EMC.
              </Card.Text>
            </Card.Body>
            
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/recovery3.avif" />
              <Card.ImgOverlay/>
            </div>

            <Card.Body>
              <Card.Title className="text-secondary">Respalde y recupere datos con la serie Dell EMC PowerProtect DD</Card.Title>
              <Card.Text>
              Aproveche el almacenamiento con protección de datos escalable, de alta velocidad y habilitado para la nube para respaldo, archivo y recuperación ante desastres.
              </Card.Text>
            </Card.Body>
            
          </Card>
        </CardGroup>
      </div>

      <div>
        <div className="libreria-container-beneficios ">
          <div className="libreria-desc-beneficios">
            <Accordion defaultActiveKey={["0"]}>
              <Accordion.Item eventKey="0" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>
                    Mejore las funcionalidades de recuperación ante desastres
                    con Dell EMC
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    La recuperación ante desastres es una funcionalidad crucial
                    para las organizaciones de todos los tamaños. La capacidad
                    de reanudar todas las operaciones de TI dentro de un período
                    específico (conocido como objetivo de tiempo de recuperación
                    o RTO) y en un punto específico del proceso de TI (conocido
                    como objetivo de punto de recuperación o RPO) es fundamental
                    para mantener la continuidad comercial, evitando la pérdida
                    de ingresos y garantizando la productividad continua.
                  </p>
                  <p>
                    Las funcionalidades de recuperación ante desastres son cada
                    vez más importantes, ya que las empresas confían más que
                    nunca en sus entornos de TI para las operaciones cotidianas.
                    Para apoyar las iniciativas de recuperación ante desastres,
                    Dell EMC proporciona soluciones de recuperación ante
                    desastres seguras, simples y económicas para las
                    instalaciones y la nube, con orquestación y automatización
                    de pruebas de recuperación ante desastres, conmutación por
                    error y conmutación por recuperación.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>El desafío: una recuperación ante desastres rentable</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    En el pasado, las organizaciones han invertido grandes
                    cantidades de dinero en hardware y software para sitios de
                    recuperación ante desastres. El ROI en estos sitios no era
                    genial, pero había algunas opciones para reducir los costos
                    de almacenamiento y hardware o minimizar el ancho de banda
                    para las ventanas de respaldo. Como resultado, la
                    recuperación ante desastres solía ser una prioridad más baja
                    que otras iniciativas de TI, y las pruebas de DR y la
                    recuperación de datos a menudo se demoraban y se
                    caracterizaban por ser procesos manuales. Inevitablemente,
                    cuando se producía un desastre, muchas organizaciones no
                    contaban con soluciones de recuperación que les permitieran
                    recuperar sus entornos de TI con la velocidad y en la medida
                    en que lo requiriera la continuidad comercial.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>
                    Soluciones de recuperación ante desastres en la nube de Dell
                    EMC
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Dell EMC, líder global en tecnología de protección de datos,
                    proporciona una solución de recuperación ante desastres que
                    aprovecha la nube para reducir los costos de recuperación
                    ante desastres y, a la vez, mejorar el RPO y el RTO. Dell
                    EMC Cloud Disaster Recovery permite que las empresas copien
                    VM respaldadas desde sus entornos en las instalaciones hasta
                    VMware Cloud on AWS, AWS o Microsoft Azure. La recuperación
                    ante desastres en la nube facilita la orquestación y la
                    automatización de las pruebas de recuperación ante
                    desastres, además de la conmutación por error de
                    recuperación ante desastres y la conmutación por
                    recuperación de cargas de trabajo de nivel 2, desde y hacia
                    la nube, en un escenario de desastre.
                  </p>

                  <p>
                    Para los clientes que ya usan Dell EMC Data Protection
                    Software para la seguridad de datos en la nube y la
                    protección de datos en la nube, Cloud Disaster Recovery
                    proporciona una experiencia de usuario conocida que minimiza
                    la necesidad de capacitación y educación adicionales. Cloud
                    Disaster Recovery también requiere un espacio físico mínimo
                    en la nube, ya que no se requiere procesamiento adicional
                    hasta que se produce una conmutación por error, y se
                    requiere un procesamiento mínimo cuando se realiza la prueba
                    o recuperación. Con Dell EMC, puede proteger los entornos de
                    producción sin los gastos y la administración de replicar
                    cargas de trabajo en un sitio de almacenamiento secundario.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>
                    Dell EMC Cyber Recovery: protección contra ciberataques
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Además de la tecnología de recuperación ante desastres, Dell
                    EMC ofrece una tecnología de recuperación cibernética de
                    vanguardia que automatiza los flujos de trabajo de un
                    extremo a otro para proteger los datos cruciales,
                    identificar la actividad sospechosa y realizar la
                    recuperación después de un ataque. Dell EMC Cyber Recovery
                    combina software, hardware y procedimientos para permitir
                    que las organizaciones se recuperen rápidamente después de
                    un ataque. Como complemento para las estrategias de
                    recuperación ante desastres, Dell EMC Cyber Recovery copia
                    los datos cruciales de una organización a una vault de
                    recuperación con divisiones, que garantiza el aislamiento de
                    la red y las amenazas contra ella, y permite la recuperación
                    limpia de una copia de datos después del ataque. El uso de
                    protecciones de vault en torno a los datos aislados también
                    protege frente a posibles ataques internos maliciosos.
                  </p>

                  <p>
                    Dell EMC Cyber Recovery también incluye lo siguiente:
                    -Análisis de seguridad programados para determinar los
                    indicadores de riesgo y alertar a los usuarios. Mediante el
                    uso de una infraestructura de análisis adaptable, Cyber
                    Recovery se mantiene por delante de las amenazas con
                    herramientas que incluyen métodos de análisis de aprendizaje
                    automático e inteligencia artificial. -Recuperación y
                    corrección mediante los procedimientos de recuperación ante
                    desastres existentes para volver a poner en línea sistemas
                    empresariales críticos. -Una metodología integral para
                    proteger datos y realizar evaluaciones de daños y análisis
                    forenses, para recuperar sistemas o corregir y eliminar
                    malware infractor. -Servicios de planificación y diseño
                    (opcionales) que pueden ayudar a determinar qué sistemas
                    cruciales para la empresa se protegerán con las
                    funcionalidades de Cyber Recovery.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>
                    Preguntas frecuentes sobre la recuperación ante desastres
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <h5>¿Qué es la recuperación ante desastres?</h5>
                  <p>
                    La recuperación ante desastres es la capacidad de reanudar
                    todas las operaciones de TI después de un desastre, como un
                    corte de energía, un ciberataque o un desastre natural.
                  </p>

                  <h5>
                    ¿Qué es la recuperación ante desastres versus el respaldo de
                    datos?
                  </h5>

                  <p>
                    Las operaciones de recuperación ante desastres y de respaldo
                    de datos implican realizar copias de datos para proteger
                    contra la pérdida de datos, los daños, los riesgos o el
                    robo. Sin embargo, si bien el respaldo de datos puede
                    implicar la copia de una parte de los datos en otro servidor
                    en el sitio o fuera del sitio, la recuperación ante
                    desastres implica la copia de una imagen completa de las
                    unidades de disco y los servidores a una ubicación fuera del
                    sitio, que no se vería afectada por un desastre en la
                    ubicación de datos principal.
                  </p>

                  <h5>¿Qué es el RPO de recuperación ante desastres?</h5>
                  <p>
                    El RPO se refiere al objetivo de punto de recuperación, que
                    es la antigüedad de los procesos de TI y los datos (el
                    tiempo desde la creación o modificación) que una
                    organización necesita para recuperarse después de un
                    desastre. Un RPO inferior estipula que se pierden menos
                    transacciones y menos información durante una interrupción
                    no planificada. Un RPO bajo o cercano a cero requiere
                    respaldos más frecuentes, lo que genera mayores costos.
                  </p>

                  <h5>¿Qué es el RTO de recuperación ante desastres?</h5>
                  <p>
                    RTO significa objetivo de tiempo de recuperación, que es la
                    cantidad de tiempo que se tarda en recuperar las operaciones
                    después de una interrupción. Un RTO bajo significa una
                    recuperación más rápida, pero generalmente a un costo mayor.
                  </p>

                  <h5>¿Cómo se realiza la recuperación ante desastres?</h5>
                  <p>
                    Para permitir la recuperación ante desastres, las
                    organizaciones deben respaldar datos con regularidad: una
                    vez cada 24 horas es un programa común. Estos respaldos de
                    recuperación ante desastres se guardan en disco o cinta y se
                    almacenan fuera del sitio o se guardan en el almacenamiento
                    de nube. En caso de un desastre, la copia más reciente de
                    los datos se recupera del almacenamiento a fin de permitir
                    que las operaciones de TI se reanuden.
                  </p>

                  <h5>
                    ¿Cuáles son los beneficios de una recuperación ante
                    desastres eficaz?
                  </h5>
                  <p>
                    La recuperación ante desastres eficaz permite que las
                    organizaciones se recuperen rápidamente después de un
                    desastre con una pérdida mínima de datos. La tecnología de
                    recuperación ante desastres superior debería ayudar a
                    reducir los costos de recuperación ante desastres al
                    minimizar la cantidad de datos que se copiarán a través de
                    la desduplicación, mediante la aceleración de la velocidad
                    de respaldo para minimizar las ventanas de respaldo, y
                    mediante la reducción de la cantidad de tiempo que le lleva
                    a los equipos de TI configurar, administrar y realizar
                    operaciones de recuperación ante desastres.
                  </p>

                  <h5>
                    ¿Puede Dell EMC Cloud Disaster Recovery proteger datos y
                    bases de datos de IBM, Oracle, Microsoft y SAP?
                  </h5>
                  <p>
                    Sí. Cloud Disaster Recovery proporciona funcionalidades de
                    recuperación y respaldo de IBM, Oracle, Microsoft y SAP.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Recovery;
