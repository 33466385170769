import React from "react";
import { Card, CardGroup, Figure } from "react-bootstrap";
import Footer from "../../../Layouts/Footer";

const DataProtection = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/Data-protection.jpg" alt="" />
        <Card.ImgOverlay className="main-libreria">
          <Card.Title className="titulo-libreria" style={{ marginTop: "13%" }}>
            <h1>Protección de Datos</h1>
          </Card.Title>

          <Card.Text className="subtitulo-libreria">
            <h5></h5>
          </Card.Text>
        </Card.ImgOverlay>
      </Card>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Soluciones</h1>
        </Card>
      </div>

      <div className="text-center text-light" style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card>
            <Card.ImgOverlay />
            <Card.Img variant="top" src="./img/cyber-recovery.avif" />
            <Card.Body className="bg-black">
              <Card.Title>-Cyber Recovery:</Card.Title>
              <Card.Text>
                La protección de su empresa comienza con la protección de sus
                datos contra ransomware. Proteja los datos cruciales de ataques
                cibernéticos y ransomware, identifique la actividad sospechosa y
                ejecute la recuperación de datos con un software de
                administración y automatización fácil de implementar.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <Card.Img variant="top" src="./img/multinube.avif" />
            <Card.Body className="bg-black">
              <Card.Title>-Multinube</Card.Title>
              <Card.Text>
                Soluciones innovadoras de protección de datos multinube con el
                ecosistema de carga de trabajo protegido más amplio, desde datos
                heredados hasta nativos de la nube.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <Card.Img variant="top" src="./img/vmware-proteccion.avif" />
            <Card.Body className="bg-black">
              <Card.Title>-VMWARE</Card.Title>
              <Card.Text>
                Dell brinda a las organizaciones la opción de planificar con
                confianza sus estrategias modernas de protección de datos a
                través de la innovación constante, la ingeniería ágil y la
                estrecha integración con VMware.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        <CardGroup>
          <Card>
            <Card.ImgOverlay />
            <Card.Img variant="top" src="./img/powerprotect-manager.avif" />
            <Card.Body className="bg-black">
              <Card.Title>-Dispositivo PowerProtect Data Manager</Card.Title>
              <Card.Text>
                Simplifique la protección de datos moderna con un dispositivo
                integrado basado en PowerProtect Data Manager.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <Card.Img variant="top" src="./img/powerprotect.avif" />
            <Card.Body className="bg-black">
              <Card.Title>-Dispositivos PowerProtect</Card.Title>
              <Card.Text>
                Simplifique sus soluciones de copia de seguridad y recuperación
                con una ventanilla única de dispositivos de copia de seguridad
                que ofrece más eficiencia y mayor agilidad para satisfacer las
                necesidades de protección de datos de recuperación cibernética,
                VMware y la nube.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <Card.Img variant="top" src="./img/powerprotect-admin.avif" />
            <Card.Body className="bg-black">
              <Card.Title>-Administrador de datos de PowerProtect</Card.Title>
              <Card.Text>
                Esta solución definida por software incluye protección de
                autoservicio, monitorización y creación de informes basadas en
                SaaS y gobernanza centralizada.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <Card.Img
              variant="top"
              src="./img/conjunto-proteccion-datos.avif"
            />
            <Card.Body className="bg-black">
              <Card.Title>-Conjunto de protección de datos</Card.Title>
              <Card.Text>
                Esta suite de software, creada sobre la base de soluciones
                probadas, proporciona una protección de datos integral, sencilla
                y flexible.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>

      <div className="text-center text-white container">
        <Card>
          <a href="https://www.veeam.com/es/data-protection-platform.html?ad=homepage-platform">
          <div className="overflow">
            <Card.Img variant="top" src="./img/data-protection2.png" />
          </div>

          </a>
         

          <Card.Body className="bg-black">
            <Card.Title>-Protección de datos moderna VEEAM PLATFORM</Card.Title>
            <Card.Text>
              Posea, controle y proteja sus datos. En cualquier nube. Modernice
              su backup para eliminar la pérdida de datos y el tiempo de
              inactividad. Proteja sus datos contra el ransomware Impulse su
              migración a cualquier nube sabiendo que sus datos están protegidos
              y siempre disponibles.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default DataProtection;
