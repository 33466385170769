import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Navbarnuevo from "./Layouts/Nav-Bar";


import Inicio from "./components/inicio";
import Empresa from "./components/empresa";
import Servicio from "./components/Servicios/Servicio";

import Soporte from "./Soporte/soporte";
import SolucionesServicios from "./components/Soluciones/solucionesServicios";
import RecursosHumanos from "./components/RecursosHumanos";
import MenuProductos from "./components/Productos/MenuProductos";
import Marcas from "./components//Relaciones/Marcas";

import About from "./components/About/About";

// {/* Estructura de ruta de almacenamiento */}

import Almacenamiento from "./components/Productos/Almacenamiento/MenuAlmacenamiento";
import Datosprimarios from "./components/Productos/Almacenamiento/datosprimarios";
import DatosNoEstructurados from "./components/Productos/Almacenamiento/DatosNoEstructurados";
import Complemento from "./components/Productos/Almacenamiento/Complemento";
import Expansion from "./components/Productos/Almacenamiento/Expansion";
import Soluciones from "./components/Productos/Almacenamiento/Soluciones";

import Networking from "./components/Productos/Networking/MenuNetWorking";
import Capacitaciones from "./components/Servicios/Capacitaciones";

// {/* Estructura de ruta de Redes */}
import Dellnetwork from "./components/Productos/Networking/RedDell/Dellnetwork";
import Switches from "./components/Productos/Networking/RedDell/Switches";
import Plataformas from "./components/Productos/Networking/RedDell/Plataformas";

import CiscoNetwork from "./components/Productos/Networking/RedCisco/CiscoNetwork";
import ArubaNetwork from "./components/Productos/Networking/RedAruba/ArubaNetwork";

// {/* Estructura de ruta de Soluciones de Oficinas */}
import Oficinas from "./components/Productos/Oficinas/oficinas";
import Oficinita from "./components/Productos/Oficinas/oficinita";

// {/* Estructura de ruta de Seguridad */}
import Seguridad from "./components/Productos/Seguridad/Seguridad";
import SeguridadIndustrial from "./components/Productos/Seguridad/IndustrialSecurity/SeguridadIndustrial";

import SeguridadUsuarios from "./components/Productos/Seguridad/UserSecurity/SeguridadUsuarios";
import UserCisco from "./components/Productos/Seguridad/UserSecurity/UserCisco";
import UserKaspersky from "./components/Productos/Seguridad/UserSecurity/UserKaspersky";
import SeguridadRedes from "./components/Productos/Seguridad/NetworkinSecurity/SeguridadRedes";

// {/* Estructura de ruta de Seguridad de Nube */}

import SeguridadNube from "./components/Productos/Seguridad/CloudSecurity/SeguridadNube";
import CiscoNube from "./components/Productos/Seguridad/CloudSecurity/CiscoNube";
import DellNube from "./components/Productos/Seguridad/CloudSecurity/DellNube";
import KasperskyNube from "./components/Productos/Seguridad/CloudSecurity/KasperskyNube";
// {/* Estructura de ruta de Seguridad */}

import Servidores from "./components/Productos/Servidores/Servidores";

// {/* Estructura de ruta de Softwares */}
import Softwares from "./components/Productos/Software/Softwares";
import Quantum from "./components/Productos/Librerias/Quantum";
import ServidoresDell from "./components/Productos/Servidores/ServidoresDell";
import ServidoresCisco from "./components/Productos/Servidores/ServidoresCisco";
import ServidoresHpe from "./components/Productos/Servidores/ServidoresHpe";

import Premisas from "./components/Soluciones/Premisa/premisas";
import SolucionNube from "./components/Soluciones/Cloud/SolucionNube";
import DataProtection from "./components/Soluciones/ProteccionDatos/DataProtection";
import SolucionSeguridad from "./components/Soluciones/Seguridad/SolucionSeguridad";
import SeguridadPerimetral from "./components/Soluciones/Seguridad/SeguridadPerimetral";
import BovedaDatos from "./components/Soluciones/Boveda/BovedaDatos";
import Recovery from "./components/Soluciones/DisasterRecovery/Recovery";
import Operativa from "./components/Soluciones/ContinuidadOperativa/Operativa";

import Asistencia from "./components/Servicios/Asistencia";
import Outsourcing from "./components/Servicios/Outsourcing";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbarnuevo />}>
            <Route index element={<Inicio />} />

            {/* Estructura de ruta de almacenamiento */}
            <Route path="Almacenamiento" element={<Almacenamiento />} />
            <Route path="DatosPrimarios" element={<Datosprimarios />} />
            <Route
              path="Datos-no-estructurados"
              element={<DatosNoEstructurados />}
            />
            <Route
              path="Complementos-Gestion-almacenamiento"
              element={<Complemento />}
            />
            <Route path="Expansion-para-servidores" element={<Expansion />} />
            <Route
              path="Soluciones-de-carga-de-almacenamiento"
              element={<Soluciones />}
            />

            {/* Estructura de ruta de Libreria */}
            <Route path="Libreria" element={<Quantum />} />

            {/* Estructura de ruta de Redes */}
            <Route path="Networking" element={<Networking />} />
            <Route path="Dell-Network" element={<Dellnetwork />} />
            <Route path="Switches-de-Ethernet" element={<Switches />} />
            <Route path="Plataformas-de-Perimetro" element={<Plataformas />} />
            <Route path="Cisco-Network" element={<CiscoNetwork />} />
            <Route path="Aruba-Network" element={<ArubaNetwork />} />

            {/* Estructura de ruta de Soluciones de Oficinas */}
            <Route path="Oficinas" element={<Oficinas />} />
            <Route path="Productos-de-Oficina" element={<Oficinita />} />

            {/* Estructura de ruta de Seguridad */}
            <Route path="Seguridad" element={<Seguridad />} />
            <Route path="Seguridad-de-Redes" element={<SeguridadRedes />} />
            <Route
              path="Seguridad-Industrial"
              element={<SeguridadIndustrial />}
            />
            <Route
              path="Seguridad-de-Usuarios-y-dispositivos"
              element={<SeguridadUsuarios />}
            />

            <Route path="Seguridad-de-Cisco-Usuarios" element={<UserCisco />} />

            <Route
              path="Seguridad-de-Kaspersky-Usuarios"
              element={<UserKaspersky />}
            />

            <Route
              path="Seguridad-de-Nubes-y-aplicaciones"
              element={<SeguridadNube />}
            />
            <Route
              path="Seguridad-de-cisco-de-Nubes-y-aplicaciones"
              element={<CiscoNube />}
            />
            <Route
              path="Seguridad-de-Dell-de-Nubes-y-aplicaciones"
              element={<DellNube />}
            />
            <Route
              path="Seguridad-de-Kaspersky-de-Nubes-y-aplicaciones"
              element={<KasperskyNube />}
            />

            {/* Estructura de ruta de Servidores/producto */}
            <Route path="Servidores" element={<Servidores />} />
            <Route path="Servidores-Dell" element={<ServidoresDell />} />
            <Route path="Servidores-Cisco" element={<ServidoresCisco />} />
            <Route path="Servidores-Hpe" element={<ServidoresHpe />} />

            {/* Estructura de ruta de Softwares/producto */}
            <Route path="Softwares" element={<Softwares />} />

            {/* Estructura de ruta de Soluciones */}
            <Route path="Premisa" element={<Premisas />} />
            <Route path="Cloud" element={<SolucionNube />} />
            <Route path="Proteccion-de-datos" element={<DataProtection />} />
            <Route
              path="Soluciones-de-seguridad"
              element={<SolucionSeguridad />}
            />
            <Route
              path="Seguridad-Perimetral"
              element={<SeguridadPerimetral />}
            />
            <Route path="Boveda-protegida-de-Datos" element={<BovedaDatos />} />
            <Route path="Disaster-Recovery" element={<Recovery />} />
            <Route path="Continuidad-Operativa" element={<Operativa />} />

            {/* Estructura de ruta de Servicio */}
            <Route path="Asistencia" element={<Asistencia />} />
            <Route path="Outsourcing" element={<Outsourcing />} />
            <Route path="Capacitaciones" element={<Capacitaciones />} />

            <Route path="empresa" element={<Empresa />} />
            
            <Route path="Servicios" element={<Servicio />} />
            <Route path="Soporte" element={<Soporte />} />
            <Route path="Soluciones" element={<SolucionesServicios />} />
            <Route path="RecursosHumanos" element={<RecursosHumanos />} />
            <Route path="MenuProductos" element={<MenuProductos />} />
            <Route path="Relaciones-comerciales" element={<Marcas />} />
            
            <Route path="Sobre-Nosotros" element={<About />} />
            

            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
