import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import Footer from "../../../Layouts/Footer";

const ServidoresCisco = () => {
  return (
    <>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/servidorTop-Cisco.jpg" alt="" />
          <Card.ImgOverlay className="main-seguridad">
            <Card.Title className="titulo-seguridad" >
              <h1>Servidores</h1>
            </Card.Title>
            <br />

            <Card.Text className="subtitulo-seguridad">
              <h5>
                Prepare su infraestructura para modernizar el presente y
                simplificar el futuro.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Productos</h1>
        </Card>
      </div>
      <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card className="bg-black text-center text-black" border="secondary">
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="./img/hyperflex-server.webp" />
            </div>

            <Card.Body className="bg-white">
              <Card.Title className="text-secondary">
                <h2>Cisco HyperFlex</h2>
              </Card.Title>

              <Card.Text>
                <h6>
                  Reúna toda su operación con una solución hiperconvergente más
                  simple y flexible
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-black" border="secondary">
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="./img/ucs-server.webp" />
            </div>

            <Card.Body className="bg-white">
              <Card.Title className="text-secondary">
                <h2>UCS Cisco</h2>
              </Card.Title>

              <Card.Text>
                <h6>
                  Cisco UCS reúne cómputo, redes y almacenamiento, todo en un
                  solo sistema para potenciar sus aplicaciones.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <Footer />
      </div>
    </>
  );
};

export default ServidoresCisco;
