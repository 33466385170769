import React from "react";
import "./almacenamiento.css";
import almacenar from "../../../Media/datacenter.mp4";
import Card from "react-bootstrap/Card";
import { Link, Outlet } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { CardGroup } from "react-bootstrap";
import Footer from "../../../Layouts/Footer";

function Almacenamiento() {
  return (
    <div>
      <div className="cover-container-almacenamiento">
        <video
          className="video-almacenamiento"
          src={almacenar}
          autoPlay
          loop
          muted
        ></video>
        <h2>Almacenamientos</h2>
        <h2>_____________________________________________________________</h2>
        <p>
          Contamos con una gran variedad de equipos para almacenamiento de datos
          desde Híbridos, Flash y Tradicionales. También disponemos de
          soluciones VTL y Software defined storage (Dell EMC).
        </p>
      </div>
      <Card
        className="servicio text-center text-light "
        bg="black"
        border="light"
      >
        <h1>Nuestra cartera de almacenamiento de datos</h1>
      </Card>

      <div className="card-container-almacenamiento text-center  text-light">
        <CardGroup>
          <Card
            as={Link}
            to="/DatosPrimarios"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="./img/datacenter.webp" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-info">
                Almacenamiento de datos Primarios
              </Card.Title>
              <Card.Text className="text-light">
                Consiga el rendimiento, la inteligencia y la integración en la
                cloud que necesita para desbloquear el valor de sus datos.
              </Card.Text>
              <Button
                variant="outline-info"
                as={Link}
                to="/DatosPrimarios"
                style={{ textDecoration: "none" }}
              >
                Explorar{" "}
              </Button>
            </Card.Body>
          </Card>

          <Card
            as={Link}
            to="/Datos-no-estructurados"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/almacenamiento-noEstructurado.jpg"
              />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-info">
                Almacenamiento de datos no estructurados
              </Card.Title>
              <Card.Text className="text-light">
                Gestione el rápido crecimiento de los datos no estructurados con
                soluciones flexibles y fáciles de gestionar.
              </Card.Text>
              <Button
                className="text-center"
                variant="outline-info"
                as={Link}
                to="/Datos-no-estructurados"
                style={{ textDecoration: "none" }}
              >
                Explorar
              </Button>
            </Card.Body>
          </Card>
          <Card
            as={Link}
            to="/Complementos-Gestion-almacenamiento"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/Almacenamiento-complemento.jpeg"
              />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-info">
                Complementos y gestión del almacenamiento
              </Card.Title>
              <Card.Text className="text-light">
                Optimice su entorno con estas tecnologías fundamentales para el
                ecosistema de almacenamiento de datos empresarial.
              </Card.Text>
              <Button
                variant="outline-info"
                as={Link}
                to="/Complementos-Gestion-almacenamiento"
                style={{ textDecoration: "none" }}
              >
                Explorar
              </Button>
            </Card.Body>
          </Card>
        </CardGroup>

        <div
          className="bg-black"
          style={{ textDecoration: "none", marginTop: "1%" }}
        >
          <CardGroup className="container">
            <Card
              as={Link}
              to="/Expansion-para-servidores"
              style={{ textDecoration: "none" }}
            >
              <div className="overflow">
                <Card.Img
                  variant="top"
                  src="./img/Almacenamiento-soluciones.webp"
                />
              </div>

              <Card.Body className="bg-black">
                <Card.Title className="text-info">
                  Expansión de JBOD PowerVault para servidores
                </Card.Title>
                <Card.Text className="text-light">
                  Amplíe su capacidad de servidor PowerEdge de forma directa y
                  sin complicaciones con las opciones de chasis de expansión
                  JBOD.
                </Card.Text>
                <Button
                  variant="outline-info"
                  as={Link}
                  to="/Expansion-para-servidores"
                  style={{ textDecoration: "none" }}
                >
                  Explorar
                </Button>
              </Card.Body>
            </Card>
            <Card
              as={Link}
              to="/Soluciones-de-carga-de-almacenamiento"
              style={{ textDecoration: "none" }}
            >
              <div className="overflow">
                <Card.Img variant="top" src="./img/datacenter-soluciones.jpg" />
              </div>

              <Card.Body className="bg-black">
                <Card.Title className="text-info">
                  Soluciones de carga de trabajo de almacenamiento de datos
                </Card.Title>
                <Card.Text className="text-light">
                  Satisfaga los cambiantes requisitos de crecimiento de los
                  datos.
                </Card.Text>
                <Button
                  variant="outline-info"
                  as={Link}
                  to="/Soluciones-de-carga-de-almacenamiento"
                  style={{ textDecoration: "none" }}
                >
                  Explorar
                </Button>
              </Card.Body>
            </Card>
          </CardGroup>
        </div>
      </div>
      <Footer />

      <section>
        <Outlet></Outlet>
      </section>
    </div>
  );
}
export default Almacenamiento;
