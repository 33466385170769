import React, { useState } from "react";
import { Button, Card, CardGroup, Figure, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../../../Layouts/Footer";

const SeguridadRedes = () => {
  const [datoshow, setShowdato] = useState(false);
  const handleClosedato = () => setShowdato(false);
  const handleShowdato = () => setShowdato(true);

  const [servidorshow, setShowservidor] = useState(false);
  const handleCloseservidor = () => setShowservidor(false);
  const handleShowservidor = () => setShowservidor(true);
  return (
    <div>
      <Card
        className=" bg-dark text-white text-center"
        style={{ marginTop: "4%" }}
      >
        <Card.Img src="img/seguridad-redes-toping.png" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad" style={{ marginTop: "-2%" }}>
            <h1>Seguridad en Infraestructura</h1>
          </Card.Title>
        </Card.ImgOverlay>
      </Card>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Productos</h1>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card onClick={handleShowdato}>
            <Card.ImgOverlay
              className="capa-seguridad-perimetral"
              style={{ textDecoration: "none" }}
            >
              <Card.Title className="text-light">
                <h3>Seguridad de Datos</h3>
              </Card.Title>
            </Card.ImgOverlay>
            <div className="overflow">
              <Card.Img
                variant="top"
                src="img/seguridad-datos-perimetral.webp"
              />
            </div>
          </Card>

          <Modal
            className="text-center"
            show={datoshow}
            onHide={handleClosedato}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Seguridad de Protección de Datos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Figure>
                <Figure.Caption>
                  <h4>Soluciones de Respaldo y protección de datos</h4>
                  <h6>
                    Enfrente los desafíos específicos de un panorama de TI en
                    constante evolución con nuestra tecnología de protección de
                    datos.
                  </h6>
                  <br />
                </Figure.Caption>
                <br />
                <Figure.Image
                  width={480}
                  height={100}
                  alt=""
                  src="./img/cyber-recovery.avif"
                />

                <Figure.Caption>
                  <h5>-Cyber Recovery:</h5>
                  <h6>
                    La protección de su empresa comienza con la protección de
                    sus datos contra ransomware. Proteja los datos cruciales de
                    ataques cibernéticos y ransomware, identifique la actividad
                    sospechosa y ejecute la recuperación de datos con un
                    software de administración y automatización fácil de
                    implementar.
                  </h6>
                </Figure.Caption>

                <br />

                <Figure.Image
                  width={470}
                  height={100}
                  alt=""
                  src="./img/multinube.avif"
                />

                <Figure.Caption>
                  <h5>-Multinube</h5>
                  <h6>
                    Soluciones innovadoras de protección de datos multinube con
                    el ecosistema de carga de trabajo protegido más amplio,
                    desde datos heredados hasta nativos de la nube.
                  </h6>
                </Figure.Caption>

                <br />

                <Figure.Image
                  width={470}
                  height={100}
                  alt=""
                  src="./img/vmware-proteccion.avif"
                />

                <Figure.Caption>
                  <h5>-VMWARE</h5>
                  <h6>
                    Dell brinda a las organizaciones la opción de planificar con
                    confianza sus estrategias modernas de protección de datos a
                    través de la innovación constante, la ingeniería ágil y la
                    estrecha integración con VMware.
                  </h6>
                  <br />
                </Figure.Caption>
                <br />

                <Figure.Caption>
                  <h4>Dispositivos de Respaldo y protección de datos</h4>
                  <h6>
                    Proteja sus datos en el perímetro, el núcleo y la nube con
                    dispositivos de protección de datos líderes en la industria
                    y soluciones definidas por software.
                  </h6>
                </Figure.Caption>
                <br />

                <br />

                <Figure.Image
                  width={470}
                  height={100}
                  alt=""
                  src="./img/powerprotect-manager.avif"
                />

                <Figure.Caption>
                  <h5>-Dispositivo PowerProtect Data Manager</h5>
                  <h6>
                    Simplifique la protección de datos moderna con un
                    dispositivo integrado basado en PowerProtect Data Manager.
                  </h6>
                </Figure.Caption>

                <br />

                <Figure.Image
                  width={470}
                  height={100}
                  alt=""
                  src="./img/powerprotect.avif"
                />

                <Figure.Caption>
                  <h5>-Dispositivos PowerProtect</h5>
                  <h6>
                    Simplifique sus soluciones de copia de seguridad y
                    recuperación con una ventanilla única de dispositivos de
                    copia de seguridad que ofrece más eficiencia y mayor
                    agilidad para satisfacer las necesidades de protección de
                    datos de recuperación cibernética, VMware y la nube.
                  </h6>
                </Figure.Caption>
                <br />

                <br />
                <Figure.Caption>
                  <h4>Software de Respaldo y protección de datos</h4>
                  <h6>
                    El software de protección de datos de Dell Technologies
                    permite una administración de datos de última generación y
                    proporciona capacidades integrales de copia de seguridad de
                    datos para cumplir las necesidades de organizaciones de
                    todos los tamaños.
                  </h6>
                  <br />
                </Figure.Caption>

                <br />
                <Figure.Image
                  width={470}
                  height={100}
                  alt=""
                  src="./img/powerprotect-admin.avif"
                />

                <Figure.Caption>
                  <h5>-Administrador de datos de PowerProtect</h5>
                  <h6>
                    Esta solución definida por software incluye protección de
                    autoservicio, monitorización y creación de informes basadas
                    en SaaS y gobernanza centralizada.
                  </h6>
                </Figure.Caption>

                <br />

                <Figure.Image
                  width={470}
                  height={100}
                  alt=""
                  src="./img/conjunto-proteccion-datos.avif"
                />

                <Figure.Caption>
                  <h5>-Conjunto de protección de datos</h5>
                  <h6>
                    Esta suite de software, creada sobre la base de soluciones
                    probadas, proporciona una protección de datos integral,
                    sencilla y flexible.
                  </h6>
                </Figure.Caption>
              </Figure>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleClosedato}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Card onClick={handleShowservidor}>
            <Card.ImgOverlay
              className="capa-seguridad-perimetral"
              style={{ textDecoration: "none" }}
            >
              <Card.Title className="text-light">
                <h3>Seguridad de Servidores</h3>
              </Card.Title>
            </Card.ImgOverlay>
            <div className="overflow">
              <Card.Img
                variant="top"
                src="img/sala-servidores-perimetral.jpg"
              />
            </div>
          </Card>

          <Modal
            className="text-center"
            show={servidorshow}
            onHide={handleCloseservidor}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Seguridad de Servidores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <CardGroup>
                  <Card className="text-center text-white">
                    <div className="overflow">
                    <Card.ImgOverlay />
                      <Card.Img
                        variant="top"
                        src="img/Endpoint-productoCisco.png"
                      />
                    </div>

                    <Card.Body className="bg-black">
                      <Card.Title className="text-secondary">
                        Seguridad de Punto Final construida para la resiliencia
                      </Card.Title>
                      <Card.Text>
                        La velocidad es importante cuando se trata de la
                        seguridad de los endpoints. Detecte, responda y
                        recupérese de los ataques con nuestra solución nativa de
                        la nube.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className="text-center text-white">
                    <div className="overflow">
                    <Card.ImgOverlay />
                      <Card.Img
                        variant="top"
                        src="img/Umbrella-productoCisco.png"
                      />
                    </div>

                    <Card.Body className="bg-black">
                      <Card.Title className="text-secondary">
                        Cisco Umbrella
                      </Card.Title>
                      <Card.Text>
                        Seguridad en la nube flexible para sus usuarios dentro y
                        fuera de la red.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className="text-center text-white">
                    <div className="overflow">
                    <Card.ImgOverlay />
                      <Card.Img variant="top" src="img/Duo-productoCisco.png" />
                    </div>

                    <Card.Body className="bg-black">
                      <Card.Title className="text-secondary">
                        Acceso seguro de Cisco por Duo
                      </Card.Title>
                      <Card.Text>
                        Ver más para salvaguardar más. Proteja a los usuarios,
                        los dispositivos y las aplicaciones en cualquier lugar.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </CardGroup>

                <div style={{ marginTop: "1%" }}>
                  <CardGroup>
                    <Card className="text-center text-white">
                      <div className="overflow">
                        <Card.ImgOverlay />
                        <Card.Img
                          variant="top"
                          src="img/User-kaspersky-endpoint1.png"
                        />
                      </div>

                      <Card.Body className="bg-black">
                        <Card.Title className="text-secondary">
                          Kaspersky Endpoint Security for Business
                        </Card.Title>
                        <Card.Text>
                          Protección adaptativa contra amenazas avanzadas
                          dirigidas a sus endpoints y usuarios.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card className="text-center text-white">
                      <div className="overflow">
                        <Card.ImgOverlay />
                        <Card.Img
                          variant="top"
                          src="img/User-kaspersky-endpoint2.png"
                        />
                      </div>

                      <Card.Body className="bg-black">
                        <Card.Title className="text-secondary">
                          Kaspersky Endpoint Detection and Response
                        </Card.Title>
                        <Card.Text>
                          Prevención de las interrupciones de la actividad al
                          permitir eliminar los riesgos de las amenazas
                          avanzadas.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card className="text-center text-white">
                      <div className="overflow">
                        <Card.ImgOverlay />
                        <Card.Img
                          variant="top"
                          src="img/User-kaspersky-endpoint3.png"
                        />
                      </div>

                      <Card.Body className="bg-black">
                        <Card.Title className="text-secondary">
                          Kaspersky Endpoint Detection and Response Optimum
                        </Card.Title>
                        <Card.Text>
                          Consigue una auténtica defensa en profundidad y
                          aumenta la eficacia de la seguridad mediante
                          respuestas automatizadas y un análisis sencillo de la
                          causa raíz.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </CardGroup>

                  <div style={{ marginTop: "1%" }}>
                    <CardGroup>
                      <Card className="text-center text-white">
                        <div className="overflow">
                          <Card.ImgOverlay />
                          <Card.Img
                            variant="top"
                            src="img/User-kaspersky-endpoint7.png"
                          />
                        </div>

                        <Card.Body className="bg-black">
                          <Card.Title className="text-secondary">
                            Kaspersky Anti Targeted Attack Platform
                          </Card.Title>
                          <Card.Text>
                            Una solución de seguridad unificada para una nueva
                            era de transformación digital.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card className="text-center text-white">
                        <div className="overflow">
                          <Card.ImgOverlay />
                          <Card.Img
                            variant="top"
                            src="img/User-kaspersky-endpoint8.png"
                          />
                        </div>

                        <Card.Body className="bg-black">
                          <Card.Title className="text-secondary">
                            Kaspersky Managed Detection and Response
                          </Card.Title>
                          <Card.Text>
                            Búsqueda y respuesta continuas de amenazas dirigidas
                            a su empresa.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card className="text-center text-white">
                        <div className="overflow">
                          <Card.ImgOverlay />
                          <Card.Img
                            variant="top"
                            src="img/User-kaspersky-endpoint9.png"
                          />
                        </div>

                        <Card.Body className="bg-black">
                          <Card.Title className="text-secondary">
                            Kaspersky Embedded Systems Security
                          </Card.Title>
                          <Card.Text>
                            Seguridad todo en uno que aborda las
                            vulnerabilidades y restricciones de los sistemas
                            integrados.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </CardGroup>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseservidor}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </CardGroup>
      </div>

      <Footer />
    </div>
  );
};

export default SeguridadRedes;
