import React from "react";
import "./Cover.css";
import Cover_Layout from "../../src/Media/Tierra2.mp4";

const Cover = () => {
  return (
    <div className="cover-container">
      <video className="video" src={Cover_Layout} autoPlay loop muted></video>
      <br/>
      <br/>
      <h2>¡EL ALIADO PARA SUS INNOVACIONES EN LA NUBE Y DATA CENTER!</h2>
      <div className="imagen-container" >
        <img className="imagenes-video-dell" src="./img/Dell-logo.png" alt="" width={111110} />
        <img className="imagenes-video-cisco" src="./img/CISCO.png" alt="" width={170} />
        <img className="imagenes-video-veam" src="./img/Veeam_logo.png" alt="" width={170} />
        <img className="imagenes-video-vmware" src="./img/VMware_logo.png" alt="" width={170} />
        <img className="imagenes-video-microsoft" src="./img/Microsoft-Logo.png" alt="" width={170} />
        
        
       
        <img className="imagenes-video-quantum" src="./img/QUANTUM.png" alt="" width={170} />
        
        

        
        <img className="imagenes-video-kaspersky" src="./img/kaspersky-logo-.png" alt="" width={170} />
       
      
        
        
       
        
        <img className="imagenes-video-HP" src="./img/HP.png" alt="" width={170} />
        
        



        
        
        


      </div>
    </div>
  );
};

export default Cover;
