import React from 'react';
import Button from "react-bootstrap/Button";
import Footer from '../../../Layouts/Footer';

function Soluciones  () {
  return (
    <>
      <>
        <div className="datosprimarios-container-PowerMax">
          <div className="datosprimarios-img-PowerMax">
            <img
              variant="top"
              src="./img/solucionesAI.avif"
              alt="about"
              height={440}
              width={700}
            ></img>
          </div>
          <div className="about-desc-PowerMax">
            <h1>Soluciones de almacenamiento para AI</h1>
            <p>Infraestructura all-flash para el aprendizaje automático de alto rendimiento y el aprendizaje profundo a escala.</p>
            <Button variant="outline-info" href="https://www.dell.com/es-es/dt/solutions/artificial-intelligence/storage-for-ai.htm#scroll=off&tab0=0">Ver más información</Button>
          </div>
        </div>

        <div className="datosprimarios-container-PowerStore">
          <div className="about-desc-PowerStore">
            <h1>Soluciones de almacenamiento para análisis</h1>
            <p>Haga realidad su estrategia de introducción a los datos y el futuro de la analítica de datos con el almacenamiento de Dell Technologies.</p>
            <Button variant="success" href="https://www.dell.com/es-es/dt/solutions/data-analytics/storage-for-data-analytics.htm#tab0=0">Ver más información</Button>
          </div>
          <div className="datosprimarios-img-PowerStore">
            <img
              variant="top"
              src="./img/soluciones-analisis.avif"
              alt="about"
              height={440}
              width={700}
            ></img>
          </div>
        </div>
      </>
      <div className="datosprimarios-container-Unity">
        <div className="datosprimarios-img-Unity">
          <img
            variant="top"
            src="./img/soluciones-SAP.avif"
            alt="about"
            height={470}
            width={700}
          ></img>
        </div>
        <div className="about-desc-Unity">
          <h1>Soluciones de almacenamiento para SAP</h1>
          <p>Una potencia y un escalamiento sin precedentes para la consolidación, la automatización y las operaciones de las implementaciones de SAP.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/solutions/sap/index.htm#scroll=off&accordion0&tab0=0">Ver más información</Button>
        </div>
      </div>
      <div className="datosprimarios-container-PowerVault">
        <div className="about-desc-PowerVault">
          <h1>Soluciones de almacenamiento para Oracle</h1>
          <p>
          Aproveche todo el potencial de Oracle y optimice con éxito los resultados empresariales con potentes soluciones de almacenamiento de datos.
          </p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/solutions/oracle/index.htm#scroll=off&accordion0&tab0=0">Ver más información</Button>
        </div>
        <div className="datosprimarios-img-PowerVault">
          <img
            variant="top"
            src="./img/soluciones-oracle.avif"
            alt="about"
            height={466}
            width={700}
          ></img>
        </div>
      </div>

      <div className="datosprimarios-container-VMAX">
        <div className="datosprimarios-img-VMAX">
          <img
            variant="top"
            src="./img/soluciones-sql.avif"
            alt="about"
            height={435}
            width={700}
          ></img>
        </div>
        <div className="about-desc-VMAX">
          <h1> Soluciones de almacenamiento para SQL</h1>
          <p>Soluciones de almacenamiento para SQL.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/solutions/microsoft-data-platform/index.htm#scroll=off&accordion0&tab0=0">Ver más información</Button>
        </div>
      </div>
      
      <br/>

      <div className="datosprimarios-container-PowerVault">
        <div className="datosprimarios-img-PowerVault">
          <img
            variant="top"
            src="./img/soluciones-vmware.avif"
            alt="about"
            height={436}
            width={700}
          ></img>
        </div>
        <div className="about-desc-PowerVault">
          <h1>Soluciones de almacenamiento para VMware</h1>
          <p>Transforme sus entornos de VMware con soluciones de almacenamiento potentes, flexibles, escalables y de probada eficacia.</p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/solutions/storage-for-vmware.htm#tab0=0">Ver más información</Button>
        </div>
      </div>
      <Footer/>

    </>
  );
}

export default Soluciones;
