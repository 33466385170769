import React from "react";
import { Button, Card, CardGroup } from "react-bootstrap";
import Footer from "../../../../Layouts/Footer";
import "./ciscostyles.css";

const CiscoNetwork = () => {
  return (
    <>
      <>
        <div className="container-img-portada-cisco">
          <Card className="portada-cisco text-white text-center">
            <Card.Img src="img/toping-cisco.png" alt=""  />

            <Card.ImgOverlay>
              <div className="about-portada-cisco">
                <h1>Soluciones visionarias de la mano de Cisco</h1>
              </div>

              <br />
            </Card.ImgOverlay>
          </Card>
        </div>
      </>
      <div className="Menu-container bg-secondary">
        <CardGroup className="bg-light">
          <Card className="bg-black text-center text-white" border="light">
            <div className="overflow">
              <Button
                className="bg-black"
                variant="link"
                href="https://www.cisco.com/site/us/en/products/networking/sdwan-routers/index.html"
              >
                {" "}
                <Card.Img
                  variant="top"
                  src="img/Router-topicmenu-cisco.webp"
                  height={230}
                />
              </Button>
            </div>

            <Card.Body>
              <Card.Title className="text-info">Routers</Card.Title>
              <Card.Text>
              Conecte y asegure redes de cualquier escala, desde el perímetro hasta la nube.
              </Card.Text>
              <Button
                variant="outline-info"
                href="https://www.cisco.com/site/us/en/products/networking/sdwan-routers/index.html"
              >
                Explorar
              </Button>{" "}
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-white" border="light">
            <div className="overflow">
              <Button
                className="bg-black"
                variant="link"
                href="https://www.cisco.com/site/us/en/products/networking/access-networking/index.html"
              >
                {" "}
                <Card.Img
                  variant="top"
                  src="./img/access-networking.webp"
                  height={230}
                />
              </Button>
            </div>

            <Card.Body>
              <Card.Title className="text-info">Redes de acceso</Card.Title>
              <Card.Text>
                Simplifique, automatice y asegure su experiencia de red con
                soluciones inalámbricas y de conmutación habilitadas por
                software.
              </Card.Text>
              <Button
                variant="outline-info"
                href="https://www.cisco.com/site/us/en/products/networking/access-networking/index.html"
              >
                Explorar
              </Button>{" "}
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-white" border="light">
            <div className="overflow">
              <Button
                className="bg-black"
                variant="link"
                href="https://www.cisco.com/site/us/en/products/networking/switches/index.html"
              >
                {" "}
                <Card.Img
                  variant="top"
                  src="./img/networking-switches-cisco.webp"
                  height={230}
                />
              </Button>
            </div>

            <Card.Body>
              <Card.Title className="text-info">Switches</Card.Title>
              <Card.Text>
              Su red necesita evolucionar para satisfacer las nuevas demandas. Así que haz tus interruptores.
              </Card.Text>
              <Button
                variant="outline-info"
                href="https://www.cisco.com/site/us/en/products/networking/switches/index.html"
              >
                Explorar
              </Button>{" "}
            </Card.Body>
          </Card>

        </CardGroup>

        <CardGroup>
        <Card className="bg-black text-center text-white" border="light">
            <div className="overflow">
              <Button
                className="bg-black"
                variant="link"
                href="https://www.cisco.com/site/us/en/products/networking/wireless/index.html"
              >
                {" "}
                <Card.Img
                  variant="top"
                  src="./img/Wireless-topic.webp"
                  height={230}
                />
              </Button>
            </div>

            <Card.Body>
              <Card.Title className="text-info">Inalambrico</Card.Title>
              <Card.Text>
              En un mundo híbrido, necesita una conexión inalámbrica que funcione mejor. Aumente la velocidad, asegure su red y ahorre tiempo ahora.
              </Card.Text>
              <Button
                variant="outline-info"
                href="https://www.cisco.com/site/us/en/products/networking/wireless/index.html"
              >
                Explorar
              </Button>{" "}
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-white" border="light">
            <div className="overflow">
              <Button
                className="bg-black"
                variant="link"
                href="https://www.cisco.com/site/us/en/products/networking/cloud-networking/index.html"
              >
                {" "}
                <Card.Img
                  variant="top"
                  src="./img/datacenter-cisco-network.webp"
                  height={230}
                />
              </Button>
            </div>

            <Card.Body>
              <Card.Title className="text-info">
              Redes de centros de datos
              </Card.Title>
              <Card.Text>
              Mantenga el control con la automatización de red líder en la industria, la visibilidad en tiempo real y las operaciones simplificadas y sin inconvenientes.
              </Card.Text>
              <Button
                variant="outline-info"
                href="https://www.cisco.com/site/us/en/products/networking/cloud-networking/index.html"
              >
                Explorar
              </Button>{" "}
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <Footer/>
    </>
  );
};

export default CiscoNetwork;
