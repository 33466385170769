import React from 'react';
import Button from "react-bootstrap/Button";
import Footer from '../../../Layouts/Footer';

function Expansion  () {
  return (
    <><div className="datosprimarios-container-PowerMax">
      <div className="datosprimarios-img-PowerMax">
        <img
          variant="top"
          src="./img/storage-powervault.avif"
          alt="about"
          height={395}
          width={700}
        ></img>
      </div>
      <div className="about-desc-PowerMax">
        <h1>PowerVault ME484 Dense JBOD</h1>
        <p>Específicamente diseñada para la escalabilidad de DAS, rendimiento y fiabilidad acelerados.</p>
        <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/powervault-me5.htm#jbod&tab0=0">Ver más información</Button>
      </div>
    </div><div className="datosprimarios-container-PowerStore">
        <div className="about-desc-PowerStore">
          <h1>Almacenamiento de conexión directa PowerVault MD1400</h1>
          <p>Cumpla con sus requisitos de rendimiento y densidad de almacenamiento con DAS simple y rentable de 12 GB para la generación más reciente de servidores PowerEdge.</p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/storage/powervault-me5.htm#jbod&tab0=0">Ver más información</Button>
        </div>
        <div className="datosprimarios-img-PowerStore">
          <img
            variant="top"
            src="./img/powervault-md1400.avif"
            alt="about"
            height={470}
            width={700}
          ></img>
        </div>
      </div><div className="datosprimarios-container-Unity">
        <div className="datosprimarios-img-Unity">
          <img
            variant="top"
            src="./img/powervault-md1420.avif"
            alt="about"
            height={475}
            width={700}
          ></img>
        </div>
        <div className="about-desc-Unity">
          <h1>Almacenamiento de conexión directa PowerVault MD1420</h1>
          <p>Cumpla con sus requisitos de rendimiento y densidad de almacenamiento con DAS simple y rentable de 12 GB para la generación más reciente de servidores PowerEdge.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/powervault-me5.htm#jbod&tab0=0">Ver más información</Button>
        </div>
        
      </div>
      <Footer/>

      </>
  );
}

export default Expansion;
