import React from 'react'

const RecursosHumanos = () => {
    return (
        <div>
            <h1>Esto es Recursos Humanos</h1>
        </div>
    );
}
export default RecursosHumanos

