import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import Footer from "../../../Layouts/Footer";

const ServidoresHpe = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/servidorTop-Hpe.webp" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad" style={{ marginTop: "20%" }}>
            <h1>Optimice su computación del perímetro a la nube</h1>
          </Card.Title>
          <br />

          <Card.Text className="subtitulo-seguridad">
            <h5>
              Potencie su plataforma del extremo a la nube con productos,
              soluciones y servicios informáticos probados y optimizados para la
              carga de trabajo.
            </h5>
          </Card.Text>
        </Card.ImgOverlay>
      </Card>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Productos</h1>
        </Card>
      </div>

      <div className="text-center text-white" style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor1-hpe.png" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                HPE GreenLake para computación
              </Card.Title>

              <Card.Text>
                Transforme la forma en que hace negocios con una experiencia y
                un modelo operativo en sus nubes distribuidas, para aplicaciones
                y datos en el perímetro, en colocaciones y en su centro de
                datos.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor2-hpe.png" />
            </div>
            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Sistemas de borde convergentes
              </Card.Title>
              <Card.Text>
                Capacidades de TI empresarial en un factor de forma compacto
                reforzado diseñado para los entornos operativos hostiles que se
                encuentran en el perímetro.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor3-hpe.png" />
            </div>
            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Servidores en rack y torre
              </Card.Title>
              <Card.Text>
                Haga crecer su negocio con servidores potentes y asequibles, del
                tamaño adecuado incluso para las cargas de trabajo más
                desafiantes.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        <br />

        <CardGroup>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor4-hpe.png" />
            </div>
            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Sistemas de infraestructura componibles
              </Card.Title>
              <Card.Text>
                Una infraestructura blade componible que impulsa cualquier carga
                de trabajo dentro de un entorno de nube híbrida.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor5-hpe.png" />
            </div>
            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Infraestructura Hiperconvergente
              </Card.Title>
              <Card.Text>
                Acelere la innovación y el tiempo de comercialización impulsando
                una resiliencia, agilidad y consolidación de cargas de trabajo
                sin precedentes con la infraestructura hiperconvergente de HPE.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor6-hpe.png" />
            </div>
            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Computación de alto rendimiento
              </Card.Title>
              <Card.Text>
                Responda a sus preguntas más importantes y resuelva sus
                problemas más complejos con las soluciones HPC, la experiencia y
                el ecosistema global de socios de HPE.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        <br />

        <CardGroup>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor7-hpe.png" />
            </div>
            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Sistemas de cómputo
              </Card.Title>
              <Card.Text>
                Acelere su modernización de TI con sistemas de cómputo
                convencionales inteligentes y optimizados para cargas de
                trabajo, disponibles como servicio.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor8-hpe.png" />
            </div>
            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Sistemas de misión crítica
              </Card.Title>
              <Card.Text>
                Soluciones resistentes y de alta velocidad que superan las
                demandas de las empresas actuales para la transformación
                digital.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.ImgOverlay />
            <div className="overflow">
              <Card.Img variant="top" src="/img/servidor9-hpe.png" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Soluciones informáticas
              </Card.Title>
              <Card.Text>
                Desde contenedores hasta seguridad de confianza cero, las
                soluciones informáticas de HPE lo tienen cubierto.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <Footer />
    </div>
  );
};

export default ServidoresHpe;
