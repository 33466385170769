import React from 'react';
import { Card } from 'react-bootstrap';
import Footer from '../../../Layouts/Footer';

const SolucionNube = () => {
  return (
    <div>
      <Card>
        <Card.Img src="img/Websiteconstruction.jpg" alt="" />
      </Card>

      <Footer/>
    </div>
  );
}

export default SolucionNube;
