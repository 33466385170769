import React from "react";
import "./footer.css";
import { Button} from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div>
        <footer className="footer-pro text-white" style={{ marginTop: "1%" }}>
          <div className="menu-cuerpo">
            <div className="menu-cuerpo">
              <Button
                className="text-white"
                variant="link"
                as={Link}
                to="/Sobre-Nosotros"
                style={{ textDecoration: "none", marginTop: "1%" }}
              >
                Sobre nosotros
              </Button>

              <Button
                className="text-white"
               href="https://sites.google.com/view/hyhsolutions/contactos"
                variant="link"
                style={{ textDecoration: "none", marginTop: "1%" }}
              >
                Contáctanos
              </Button>

              <Button
                className="text-white"
                variant="link"
                as={Link}
                to="/Relaciones-comerciales"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                  color: "white",
                }}
              >
                Relaciones comerciales
              </Button>

              <Button
                className="text-white"
                variant="link"
                href="https://sites.google.com/view/hyhsolutions/novedades"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                  color: "white",
                }}
              >
                Novedades
              </Button>
            </div>

            <div className="redes-sociales " style={{ textDecoration: "none" }}>
              <Button
                className="text-white"
                variant="link"
                href="https://es-la.facebook.com/hyhsolutions20/"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                }}
              >
                <img src="./img/facebook.png" alt="" width={30} height={30} />.
                H&H Solutions SRL
              </Button>

              <Button
                className="text-white r"
                variant="link"
                href="https://www.instagram.com/hyhsolutions/?hl=es"
                style={{ textDecoration: "none", marginTop: "1%" }}
              >
                <img src="./img/instagram.png" alt="" width={30} height={30} />
                @hyhsolutions
              </Button>

              <Button
                className="text-white"
                variant="link"
                href="https://twitter.com/HyHSolutions"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                  color: "white",
                }}
              >
                <img src="./img/twiter.png" alt="" width={30} height={30} />
                @HyHSolutions
              </Button>

              <Button
                className="text-white"
                variant="link"
                href="https://www.linkedin.com/company/h-h-solutions-srl?trk=ppro_cprof"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                  color: "white",
                }}
              >
                <img src="./img/linkedin.png" alt="" width={30} height={30} />.
                H&H Solutions SRL
              </Button>
            </div>

            <div className="coping">
              <h6 className="text-white">
                &copy; 2022 - H&H Solutions SRL. Todos los derechos reservados
                (Version 1).
              </h6>
            </div>
          </div>
          <div className="nota">
          <h6 >Nota: La página posee una estructura para visualizarse de forma optima en resolucion entre 1824px y 1200px, es decir para pantallas grandes, desktops y laptops.</h6>

          </div>
          
        </footer>
      </div>
      

      <section>
        <Outlet></Outlet>
      </section>
    </>
  );
};

export default Footer;
