import React, { useState }from "react";
import { Button, Card, CardGroup, Figure, Modal } from "react-bootstrap";
import Footer from "../../Layouts/Footer";

export default function Capacitaciones() {
  const [dellshow, setShowdell] = useState(false);
  const handleClosedell = () => setShowdell(false);
  const handleShowdell = () => setShowdell(true);

  const [vmwareshow, setShowvmware] = useState(false);
  const handleClosevmware = () => setShowvmware(false);
  const handleShowvmware = () => setShowvmware(true);

  const [veeamshow, setShowveeam] = useState(false);
  const handleCloseveeam = () => setShowveeam(false);
  const handleShowveeam = () => setShowveeam(true);

  const [quantumshow, setShowquantum] = useState(false);
  const handleClosequantum = () => setShowquantum(false);
  const handleShowquantum = () => setShowquantum(true);
  return (
    <div>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/formacion-toping.avif" alt="" />
          <Card.ImgOverlay className="main-software">
            <Card.Title className="titulo-software">
              <h1>Formación</h1>
            </Card.Title>

            <Card.Text className="subtitulo-software">
              <h5>
                En H&H Solutions Proporcionamos capacitación y certificación
                reconocidas para acelerar el desarrollo de profesionales con
                tecnologías nuevas y emergentes.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Entrenamientos:</h1>
        </Card>
      </div>

      <div className="text-center" style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card className="bg-black" border="white" onClick={handleShowdell}>
            <div className="overflow">
              <Card.Img style={{cursor:"pointer"}} variant="top" src="img/Dell-network.png" />
              
            </div>
          </Card>

          <Modal
          show={dellshow}
          onHide={handleClosedell}
          size="xs"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="text-center"
        >
          <Modal.Header closeButton>
            <Modal.Title>Entrenamientos Dell</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Figure>
              <Figure.Image
                width={10}
                height={20}
                alt=""
                src="./img/entrenamiento1.avif"
                className="container"
              />
              <Figure.Caption style={{marginTop:"4%"}}>
                <h5>Capacitamos con lo siguientes entrenamientos:</h5>
               
                <h6>
                  -Data Domain
                </h6>
                <h6>-Unity</h6>
                <h6>-Isilon</h6>
                <h6>-Servidores</h6>
                <h6>-Switch</h6>
                <h6>-Networker</h6>
                <h6>-VDI</h6>
                <h6>-Recovery Point</h6>
              </Figure.Caption>
            </Figure>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleClosedell}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>



          <Card className="bg-black" border="white" onClick={handleShowveeam}>
            <div className="overflow">
              <Card.Img style={{cursor:"pointer"}} variant="top" src="img/Veeam-Prueba.jpg" />
              
            </div>
          </Card>


          <Modal
          show={vmwareshow}
          onHide={handleClosevmware}
          size="xs"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="text-center"
        >
          <Modal.Header closeButton>
            <Modal.Title>Entrenamientos Vmware</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Figure>
              <Figure.Image
                width={500}
                height={300}
                alt=""
                src="./img/entrenamiento3.avif"
                className="container"
              />
              <Figure.Caption style={{marginTop:"4%"}}>
              <h5>Capacitamos con lo siguientes entrenamientos:</h5>
               
               <h6>
                 -Administración
               </h6>
               <h6>-Instalación y Configuración</h6>
              </Figure.Caption>
            </Figure>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleClosevmware}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>




          <Card className="bg-black" border="white" onClick={handleShowvmware}>
            <div className="overflow">
              <Card.Img style={{cursor:"pointer"}} variant="top" src="img/VMware_logo-software.png" />
              
            </div>
          </Card>

          <Modal
          show={veeamshow}
          onHide={handleCloseveeam}
          size="xs"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="text-center"
        >
          <Modal.Header closeButton>
            <Modal.Title>Entrenamientos Veeam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Figure>
              <Figure.Image
                width={500}
                height={300}
                alt=""
                src="./img/entrenamiento2.avif"
                className="container"
              />
              <Figure.Caption style={{marginTop:"4%"}}>
              <h5>Capacitamos con lo siguientes entrenamientos:</h5>
               
                <h6>
                  -Veeam Backup and Replication
                </h6>
                <h6>-Veeam One</h6>
                
                
              </Figure.Caption>
            </Figure>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseveeam}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


          <Card className="bg-black" border="white" onClick={handleShowquantum}>
            <div className="overflow">
              <Card.Img style={{cursor:"pointer"}} variant="top" src="img/quantum-logo2.jpg" />
              
            </div>
          </Card>


          <Modal
          show={quantumshow}
          onHide={handleClosequantum}
          size="xs"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="text-center"
        >
          <Modal.Header closeButton>
            <Modal.Title>Entrenamientos Quantum</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Figure>
              <Figure.Image
                width={200}
                height={300}
                alt=""
                src="./img/entrenamiento4.avif"
                className="container"
              />
              <Figure.Caption style={{marginTop:"4%"}}>
              <h5>Capacitamos entrenándote con:</h5>
               
               <h6>
                 -Librerías
               </h6>
               
              </Figure.Caption>
            </Figure>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleClosequantum}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        </CardGroup>
      </div>

      <Footer />
    </div>
  );
}
