import React from "react";
import { Card, CardGroup, Carousel, Figure } from "react-bootstrap";
import Footer from "../../Layouts/Footer";
import { Link, Outlet } from "react-router-dom";
import almacenar from "../.././Media/menuProduct.mp4";
const MenuProductos = () => {
  return (
    <div>
      <div style={{marginTop:"3%"}} className="cover-container-almacenamiento">
        <video
          className="video-almacenamiento"
          src={almacenar}
          autoPlay
          loop
          muted
        ></video>
        <h2>Productos</h2>
        
        <p>
         
        </p>
       
       
      </div>

      

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Cartera de Nuestros Productos</h1>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Almacenamiento"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="/img/productos-almacenamientos.jpg"
              />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Almacenamientos</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Libreria"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="./img/producto-libreria.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Librerias Quantum</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Networking"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/producto-networking.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">
                Redes y Telecomunicaciones
              </Card.Title>
            </Card.Body>
          </Card>
        </CardGroup>

        <CardGroup>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Productos-de-Oficina"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/producto-oficina.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Oficinas</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Seguridad"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/producto-seguridad.jpg" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Seguridad</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Servidores"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/productos-servidores.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Servidores</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Softwares"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/producto-software.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Software</Card.Title>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>

      <div
        className="funcionalidad-nube-contenedor"
        style={{ marginTop: "1%" }}
      >
        <div className="funcionalidad-nube-img">
          <Figure>
            <Figure.Image
              className="rounded"
              variant="top"
              width={1401}
              height={100}
              alt=""
              src="./img/producto-panel2.webp"
              style={{ marginTop: "7%", marginLeft: "10%" }}
            />
          </Figure>
        </div>
        <div className="funcionalidad-nube-desc">
          <h1 className="text-secondary" style={{ marginTop: "4%" }}>
            Entrega a Domicilio
          </h1>
          <p>
            Contamos con una amplia variedad de productos y soluciones
            tecnológicas. Puedes tenerlo en tus manos al alcance de un
            clic! Ofrecemos entregas de los productos que deseas a la puerta de
            tu domicilio de forma rápida y efectiva.  ¡Puedes pedir todo lo que
            quieras, nosotros te lo llevamos!
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MenuProductos;
