import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import Footer from "../../../../Layouts/Footer";

const UserKaspersky = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/producto-seguridad-kaspersky.jpg" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad">
            <h1>Productos de seguridad de Usuarios de Kaspersky</h1>
          </Card.Title>
          <br />
        </Card.ImgOverlay>
      </Card>

      <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card className="text-center text-white">
            <div className="overflow">
              <Card.ImgOverlay />
              <Card.Img variant="top" src="img/User-kaspersky-endpoint1.png" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Kaspersky Endpoint Security for Business
              </Card.Title>
              <Card.Text>
                Protección adaptativa contra amenazas avanzadas dirigidas a sus
                endpoints y usuarios.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center text-white">
            <div className="overflow">
              <Card.ImgOverlay />
              <Card.Img variant="top" src="img/User-kaspersky-endpoint2.png" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Kaspersky Endpoint Detection and Response
              </Card.Title>
              <Card.Text>
                Prevención de las interrupciones de la actividad al permitir
                eliminar los riesgos de las amenazas avanzadas.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center text-white">
            <div className="overflow">
              <Card.ImgOverlay />
              <Card.Img variant="top" src="img/User-kaspersky-endpoint3.png" />
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Kaspersky Endpoint Detection and Response Optimum
              </Card.Title>
              <Card.Text>
                Consigue una auténtica defensa en profundidad y aumenta la
                eficacia de la seguridad mediante respuestas automatizadas y un
                análisis sencillo de la causa raíz.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        <div style={{ marginTop: "1%" }}>
          <CardGroup>
            <Card className="text-center text-white">
              <div className="overflow">
                <Card.ImgOverlay />
                <Card.Img
                  variant="top"
                  src="img/User-kaspersky-endpoint4.png"
                />
              </div>

              <Card.Body className="bg-black">
                <Card.Title className="text-secondary">
                  Kaspersky Security for Microsoft Office 365
                </Card.Title>
                <Card.Text>
                  Protección avanzada de Microsoft Office 365 para llenar el
                  vacío de seguridad dejado por los controles de seguridad
                  integrados.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="text-center text-white">
              <div className="overflow">
                <Card.ImgOverlay />
                <Card.Img
                  variant="top"
                  src="img/User-kaspersky-endpoint5.png"
                />
              </div>

              <Card.Body className="bg-black">
                <Card.Title className="text-secondary">
                  Kaspersky Security for Internet Gateway
                </Card.Title>
                <Card.Text>
                  Proteja la infraestructura de su empresa frente a amenazas
                  basadas en web.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="text-center text-white">
              <div className="overflow">
                <Card.ImgOverlay />
                <Card.Img
                  variant="top"
                  src="img/User-kaspersky-endpoint6.png"
                />
              </div>

              <Card.Body className="bg-black">
                <Card.Title className="text-secondary">
                  Kaspersky Sandbox
                </Card.Title>
                <Card.Text>
                  Seguridad integral del correo electrónico para comunicaciones
                  empresariales fluidas.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </div>

        <div style={{ marginTop: "1%" }}>
          <CardGroup>
            <Card className="text-center text-white">
              <div className="overflow">
                <Card.ImgOverlay />
                <Card.Img
                  variant="top"
                  src="img/User-kaspersky-endpoint7.png"
                />
              </div>

              <Card.Body className="bg-black">
                <Card.Title className="text-secondary">
                  Kaspersky Anti Targeted Attack Platform
                </Card.Title>
                <Card.Text>
                  Una solución de seguridad unificada para una nueva era de
                  transformación digital.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="text-center text-white">
              <div className="overflow">
                <Card.ImgOverlay />
                <Card.Img
                  variant="top"
                  src="img/User-kaspersky-endpoint8.png"
                />
              </div>

              <Card.Body className="bg-black">
                <Card.Title className="text-secondary">
                  Kaspersky Managed Detection and Response
                </Card.Title>
                <Card.Text>
                  Búsqueda y respuesta continuas de amenazas dirigidas a su
                  empresa.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="text-center text-white">
              <div className="overflow">
                <Card.ImgOverlay />
                <Card.Img
                  variant="top"
                  src="img/User-kaspersky-endpoint9.png"
                />
              </div>

              <Card.Body className="bg-black">
                <Card.Title className="text-secondary">
                  Kaspersky Embedded Systems Security
                </Card.Title>
                <Card.Text>
                  Seguridad todo en uno que aborda las vulnerabilidades y
                  restricciones de los sistemas integrados.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserKaspersky;
