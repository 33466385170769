import React from 'react';
import Button from "react-bootstrap/Button";
import Footer from '../../../Layouts/Footer';

function Complemento () {
  return (
    <><>
      <>
        <div className="datosprimarios-container-PowerMax">
          <div className="datosprimarios-img-PowerMax">
            <img
              variant="top"
              src="./img/cloudiq.avif"
              alt="about"
              height={464}
              width={700}
            ></img>
          </div>
          <div className="about-desc-PowerMax">
            <h1> CloudIQ</h1>
            <p>La aplicación basada en la nube aprovecha el aprendizaje automático para supervisar y medir de forma proactiva el estado general del entorno de su centro de datos.</p>
            <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/cloudiq.htm#scroll=off">Ver más información</Button>
          </div>
        </div>

        <div className="datosprimarios-container-PowerStore">
          <div className="about-desc-PowerStore">
            <h1> DataIQ</h1>
            <p>Descubra, comprenda y actúe sobre todos los datos no estructurados de su entorno.</p>
            <Button variant="success" href="https://www.dell.com/es-es/dt/storage/dataiq.htm#scroll=off">Ver más información</Button>
          </div>
          <div className="datosprimarios-img-PowerStore">
            <img
              variant="top"
              src="./img/dataiq.avif"
              alt="about"
              height={395}
              width={700}
            ></img>
          </div>
        </div>
      </>
      <div className="datosprimarios-container-Unity">
        <div className="datosprimarios-img-Unity">
          <img
            variant="top"
            src="./img/connectrix-san.avif"
            alt="about"
            height={445}
            width={700}
          ></img>
        </div>
        <div className="about-desc-Unity">
          <h1>Connectrix SAN</h1>
          <p>Solución Storage Area Networking que permite a los servidores compartir almacenamiento basado en bloques.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/connectrix.htm#scroll=off&tab0=0">Ver más información</Button>
        </div>
      </div>
      <div className="datosprimarios-container-PowerVault">
        <div className="about-desc-PowerVault">
          <h1>VPLEX</h1>
          <p>
          Permite la movilidad continua de datos y disponibilidad.
          </p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/storage/vplex.htm#scroll=off&tab0=0">Ver más información</Button>
        </div>
        <div className="datosprimarios-img-PowerVault">
          <img
            variant="top"
            src="./img/VPLEX.avif"
            alt="about"
            height={365}
            width={700}
          ></img>
        </div>
      </div>

      <div className="datosprimarios-container-VMAX">
        <div className="datosprimarios-img-VMAX">
          <img
            variant="top"
            src="./img/PowerPath.avif"
            alt="about"
            height={395}
            width={700}
          ></img>
        </div>
        <div className="about-desc-VMAX">
          <h1>PowerPath</h1>
          <p>Gestione de forma inteligente las rutas de datos entre los servidores y el almacenamiento.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/powerpath-intelligent-multipathing-software.htm#scroll=off">Ver más información</Button>
        </div>
      </div>
      <br />
      <br />

      <div className="datosprimarios-container-PowerMax">
        <div className="datosprimarios-img-PowerMax">
          <img
            variant="top"
            src="./img/SRM-.avif"
            alt="about"
            height={395}
            width={700}
          ></img>
        </div>
        <div className="about-desc-PowerMax">
          <h1> Storage Resource Manager (SRM)</h1>
          <p>Monitorización e informes en las instalaciones para optimizar los entornos de almacenamiento heterogéneos.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/storage-resource-manager.htm#scroll=off">Ver más información</Button>
        </div>
      </div>

      <div className="datosprimarios-container-PowerStore">
        <div className="about-desc-PowerStore">
          <h1> AppSync</h1>
          <p>Gestión de datos de copia integrada y consistente entre aplicaciones.</p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/storage/appsync-copy-data-management-software.htm#scroll=off">Ver más información</Button>
        </div>
        <div className="datosprimarios-img-PowerStore">
          <img
            variant="top"
            src="./img/AppSync.avif"
            alt="about"
            height={385}
            width={700}
          ></img>
        </div>
      </div>
    </><div className="datosprimarios-container-Unity">
        <div className="datosprimarios-img-Unity">
          <img
            variant="top"
            src="./img/Automation.avif"
            alt="about"
            height={445}
            width={700}
          ></img>
        </div>
        <div className="about-desc-Unity">
          <h1>Automatización del almacenamiento y recursos para DevOps</h1>
          <p>Integraciones de almacenamiento con plataformas de desarrollo y automatización.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/solutions/devops/index.htm#scroll=off">Ver más información</Button>
        </div>
      </div><div className="datosprimarios-container-PowerVault">
        <div className="about-desc-PowerVault">
          <h1>CloudLink</h1>
          <p>
          Solución de cifrado de datos y gestión clave para datos estáticos en entornos de cloud pública, privada e híbrida.
          </p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/storage/cloud-link.htm#scroll=off">Ver más información</Button>
        </div>
        <div className="datosprimarios-img-PowerVault">
          <img
            variant="top"
            src="./img/cloudlink.avif"
            alt="about"
            height={395}
            width={700}
          ></img>
        </div>
      </div><div className="datosprimarios-container-VMAX">
        <div className="datosprimarios-img-VMAX">
          <img
            variant="top"
            src="./img/kubernetes-storage.avif"
            alt="about"
            height={500}
            width={700}
          ></img>
        </div>
        <div className="about-desc-VMAX">
          <h1>Almacenamiento moderno para Kubernetes y contenedores</h1>
          <p>El almacenamiento empresarial hecho realidad para Kubernetes con integración y automatización simples y coherentes para las operaciones de desarrollo y la tecnología informática en todo el almacenamiento y las aplicaciones stateful nativas de la cloud.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/solutions/kubernetes-containers/kubernetes-storage.htm#scroll=off">Ver más información</Button>
        </div>
      </div>
      <Footer/>

      </>
  
  );
}

export default Complemento;
