import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../../Layouts/Footer";
import "./servi.css";

const Servidores = () => {
  return (
    <>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/menu-servidores.jpg" alt="" />
          <Card.ImgOverlay className="main-server">
            <Card.Title className="titulo-server">
              <h1>Servidores</h1>
            </Card.Title>

            <Card.Text className="subtitulo-server">
              <p>
                El principal objetivo es proveer recursos útiles para los
                usuarios, tales como, almacenamiento web, de e-mail, protección
                de datos, entre otros. Proveemos servidores que ofrecen todo
                tipo de información y, también, ayudan en la gestión de
                programas que los usuarios u otros ordenadores requieren.
              </p>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>

        <div
          className="text-light text-center"
          style={{ marginTop: "1%" }}     
        >
          <CardGroup >
          <Card
            className="text-light"
            variant="link"
            as={Link}
            to="/Servidores-Cisco"
            border="dark"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
            <Card.Img
              className="bg-white"
              variant="top"
              src="./img/Cisco-network.png"
              
            />

            </div>
            
            
          </Card>

            <Card
              as={Link}
              to="/Servidores-Dell"
              variant="link"
              border="dark"
             
            >
              <div className="overflow">
                <Card.Img
                  className="bg-white"
                  variant="top"
                  src="./img/Dell-network.png"
                 
                />
              </div>
            </Card>
            <Card
              border="dark"
              className="text-black "
              variant="link"
              as={Link}
              to="/Servidores-Hpe"
              
            >
              <div className="overflow">
                <Card.Img
                  className="bg-white "
                  variant="top"
                  src="./img/HPE.png"
                  
                />
              </div>
            </Card>
          </CardGroup>
        </div>
      </div>



      <div>
        <CardGroup></CardGroup>
      </div>

      <Footer/>
    </>
  );
};

export default Servidores;
