import React from "react";
import { Button, Card, CardGroup} from "react-bootstrap";
import Footer from "../Layouts/Footer";
import { Form, Link } from "react-router-dom";

const Soporte = () => {
  
  return (
    <div>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img
            style={{ marginTop: "-4%" }}
            src="img/soporte-toping.avif"
            alt=""
          />
          <Card.ImgOverlay className="main-software">
            <Card.Title className="titulo-software">
              <h1>Soporte</h1>
            </Card.Title>

            <Card.Text className="subtitulo-software">
              <h5>
                Estamos listo para brindarte el soporte que necesitas a
                cualquier hora de cualquier dia, en cualquier momento, aqui y
                ahora en H&H Solutions.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <div style={{ marginTop: "1%" }}>
        <CardGroup className="text-center">
          <Card border="white">
            <Card.Img
              className="pe-none"
              variant="top"
              src="img/soporte1.avif"
            />

            <Card.Body>
              <Card.Title>
                Comunícate con nuestro departamento de ventas de productos
              </Card.Title>

              <Button
                className=" pe-none text-black"
                variant="link"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                }}
              >
                <img src="./img/correo.png" alt="" width={30} height={30} />
                Ventas@hyhsolutions.com.do
              </Button>

              <Button
                className="pe-none text-black"
                variant="link" 
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                }}
              >
                <img
                  src="./img/telefono-movil.png"
                  alt=""
                  width={30}
                  height={30}
                />
                (809) 475-4474 / ext 221
              </Button>

              <Button
                className="text-black"
                variant="link"
                href="https://sites.google.com/view/hyhsolutions/cotizacion"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                }}
              >
                <img
                  src="./img/registro-en-linea.png"
                  alt=""
                  width={30}
                  height={30}
                />
                Solicita tu compra
              </Button>
            </Card.Body>
          </Card>
          <Card border="white">
            <Card.Img
              className="pe-none"
              variant="top"
              src="img/soporte2.avif"
            />

            <Card.Body>
              <Card.Title>
                {" "}
                Puedes solicitar nuestros servicios y generar tu ticket.{" "}
              </Card.Title>

              <Button
                className="text-black pe-none"
                variant="link"
                href="https://www.google.com/maps/dir//hyh+solutions/@18.4915153,-69.9892483,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8eaf8a050019e06b:0x1587d08728311679!2m2!1d-69.9640397!2d18.4754682"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                }}
              >
                <img src="./img/correo.png" alt="" width={30} height={30} />
                centrodeservicios@hyhsolutions.com.do
              </Button>

              <Button
                className="text-black pe-none"
                variant="link"
                href="https://www.google.com/maps/dir//hyh+solutions/@18.4915153,-69.9892483,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8eaf8a050019e06b:0x1587d08728311679!2m2!1d-69.9640397!2d18.4754682"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                }}
              >
                <img
                  src="./img/telefono-movil.png"
                  alt=""
                  width={30}
                  height={30}
                />
                (809) 475-4474 / ext 255
              </Button>

              <Button
                className="text-black"
                variant="link"
                href="https://desk.zoho.com/agent/hmondesihyhsolutionscom/departamento-soporte-tecnico-hyh-solutions/tickets/list/all-cases"
                style={{
                  textDecoration: "none",
                  marginTop: "1%",
                }}
              >
                <img
                  src="./img/registro-en-linea.png"
                  alt=""
                  width={30}
                  height={30}
                />
                Genera tu Ticket aqui
              </Button>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      






      <Footer />
    </div>
  );
};
export default Soporte;
