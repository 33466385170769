import React from "react";
import "./info.css";

const Info = () => {
  return (
    <div className="info-container">
      <div className="info">
        <h1>¡ Trabajando juntos crearemos algo unico !</h1>
      </div>
    </div>
  );
};

export default Info;
