import React from "react";
import { Button, Card, Figure } from "react-bootstrap";

import Footer from "../../Layouts/Footer";

const Asistencia = () => {
  return (
    <div>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img style={{marginTop:"5%"}} src="img/asistencia-toping.avif" alt="" />
          <Card.ImgOverlay className="main-software">
            <Card.Title className="titulo-software">
              <h1>Asistencia</h1>
            </Card.Title>

            <Card.Text className="subtitulo-software">
              <h5>
                En H&H Solutions contamos con la disponibilidad de software de
                aplicacion como productos al alcance de tus manos.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>


      <div className="libreria-container">
        <div className="libreria-desc">
          <h1>
          ¿En qué consiste nuestro servicio de asistencia?
          </h1>

          <p>
          Nuestros especialistas se comunican con nuestros clientes a través de las diferentes vías de comunicación(correo, vía telefonica, pagina web, etc), cada asistencia es manejada a través de un sistema de mesa de ayuda. Toda actividad es canalizada por esta vía, a fin de que se obtenga una comunicación más fluida y dinámica con nuestros clientes
          </p>
          <Button variant="dark" href="https://sites.google.com/view/hyhsolutions/contactos" size="lg">
            CONTACTA CON NOSOTROS
          </Button>
        </div>
        <div>
          <Figure>
            <Figure.Image
              variant="top"
              className="rounded"
              width={1200}
              height={900}
              alt="171x180"
              src="/img/asistencia1.avif"
              style={{ marginTop: "10%", marginLeft: "-7%" }}
            />
          </Figure>
        </div>
      </div>

      <div>

      </div>
      <Footer/>
    </div>
  );
};

export default Asistencia;
