import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import almacenar from "../.././Media/MenuServicios-Toping.mp4";
import Footer from "../../Layouts/Footer";
const CentroDeServicio = () => {
  return (
    <div>
      <div className="cover-container-almacenamiento">
        <video
          className="video-almacenamiento"
          src={almacenar}
          autoPlay
          loop
          muted
        ></video>
        <h2>Servicios</h2>

        <p>
        Aportamos nuestras experiencias para una mayor satisfacción en los servicios que brindamos en H&H Solutions. De esta forma hacemos posible nuestro compromiso haciendo de lo imposible, algo simple.
        </p>
      </div>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Nuestra Cartera de Servicios</h1>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
      <CardGroup>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Asistencia"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/servicio-asistencia.avif" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Asistencia</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Capacitaciones"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="./img/servicio-formacion.avif" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Formación</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Outsourcing"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="/img/servicio-outsourcing.avif"
              />
            </div>

            <Card.Body>
              <Card.Title className="text-white">
               Outsourcing
              </Card.Title>
            </Card.Body>
          </Card>

        </CardGroup>
      </div>

      <Footer/>
    </div>
  );
};
export default CentroDeServicio;
