import React from "react";
import { Button, Card, CardGroup } from "react-bootstrap";
import Footer from "../../../../Layouts/Footer";

const UserCisco = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/producto-seguridad-cisco.jpg" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad">
            <h1>Productos de seguridad de Usuarios de Cisco</h1>
          </Card.Title>
          <br />
        </Card.ImgOverlay>
      </Card>

      <div style={{marginTop:"1%"}}>
        <CardGroup>
          <Card className="text-center text-white">
            <div className="overflow">
              <a href="https://www.cisco.com/site/us/en/products/security/endpoint-security/secure-endpoint/index.html">
              <Card.Img variant="top" src="img/Endpoint-productoCisco.png" />

              </a>
              
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Seguridad de Punto Final construida para la resiliencia
              </Card.Title>
              <Card.Text>
                La velocidad es importante cuando se trata de la seguridad de
                los endpoints. Detecte, responda y recupérese de los ataques con
                nuestra solución nativa de la nube, y reduzca los tiempos de
                remediación hasta en un 85 por ciento.
              </Card.Text>
              <Button
                variant="outline-info"
                href="https://www.cisco.com/site/us/en/products/security/endpoint-security/secure-endpoint/index.html"
                style={{ textDecoration: "none" }}
              >
                Explorar Seguridad Punto Final
              </Button>{" "}
            </Card.Body>
          </Card>
          <Card className="text-center text-white">
            <div className="overflow">
              <a href="https://umbrella.cisco.com/?utm_medium=web-referral&utm_source=cisco">
              <Card.Img variant="top" src="img/Umbrella-productoCisco.png" />

              </a>
              
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">Cisco Umbrella</Card.Title>
              <Card.Text>
                Seguridad en la nube flexible para sus usuarios dentro y fuera
                de la red.
              </Card.Text>
              <Button
                variant="outline-info"
                href="https://umbrella.cisco.com/?utm_medium=web-referral&utm_source=cisco"
                style={{ textDecoration: "none" }}
              >
                Explorar Cisco Umbrella
              </Button>{" "}
            </Card.Body>
          </Card>
          <Card className="text-center text-white">
            <div className="overflow">
              <a href="https://duo.com/?utm_source=cisco&utm_medium=referral#eyJoYXNoIjoiIiwic2VhcmNoIjoiP3V0bV9zb3VyY2U9Y2lzY28mdXRtX21lZGl1bT1yZWZlcnJhbCJ9">
              <Card.Img variant="top" src="img/Duo-productoCisco.png"  />

              </a>
              
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-secondary">
                Acceso seguro de Cisco por Duo
              </Card.Title>
              <Card.Text>
                Ver más para salvaguardar más. Proteja a los usuarios, los
                dispositivos y las aplicaciones en cualquier lugar.
              </Card.Text>
              <Button
                variant="outline-info"
                href="https://duo.com/?utm_source=cisco&utm_medium=referral#eyJoYXNoIjoiIiwic2VhcmNoIjoiP3V0bV9zb3VyY2U9Y2lzY28mdXRtX21lZGl1bT1yZWZlcnJhbCJ9"
                style={{ textDecoration: "none" }}
              >
                Explorar Duo
              </Button>{" "}
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <Footer/>
    </div>
  );
};

export default UserCisco;
