import React from "react";
import { Accordion, Card, CardGroup, Figure } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../../../Layouts/Footer";
import "./seguridadnube.css";

const SeguridadNube = () => {
  return (
    <div>
      <Card className=" bg-black text-white text-center">
        <Card.Img src="img/seguridad-nube-toping.jpg" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad">
            <h1>Seguridad de Aplicaciones</h1>
          </Card.Title>
          <br />
        </Card.ImgOverlay>
      </Card>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Productos</h1>
        </Card>
      </div>

      <div
        className="funcionalidad-nube-contenedor"
        style={{ marginTop: "1%" }}
      >
        <div className="funcionalidad-nube-img">
          <Figure>
            <Figure.Image
            className="rounded"
            variant="top"
              width={1301}
              height={180}
              alt=""
              src="./img/cloud-security2.webp"
              style={{ marginTop: "7%", marginLeft: "10%" }}
            />
            
          </Figure>
          
        </div>
        <div className="funcionalidad-nube-desc">
          <h1>Cisco Tetration</h1>
          <p>
            Cisco Tetration ofrece una protección integral para la carga de
            trabajo de Data Centers multinube habilitando un modelo de
            "confianza cero" mediante la segmentación. Este enfoque le permite
            identificar incidentes de seguridad más rápido, frenar el movimiento
            lateral y reducir la superficie de ataque. El planteamiento
            agnóstico de las infraestructuras de Tetration permite las cargas de
            trabajo tanto de nube pública como en las instalaciones.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SeguridadNube;
