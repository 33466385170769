import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import Footer from "../../../../Layouts/Footer";
import "../networking.css";

const Switches = () => {
  return (
    <>
      
      <div className="switches-datacenter">
        <Card
          className="servicio text-center text-light "
          bg="black"
          border="light"
        >
          <h1>Switches de centro de datos PowerSwitch</h1>
        </Card>
        <CardGroup className="container">
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
            <Card.Img
              variant="top"
              src="./img/switches1gbe.avif"
              height={300}
            />

            </div>
            
            <Card.Body>
              <Card.Title className="text-info">
                <h2>Switches Dell PowerSwitch serie S de 1 GbE</h2>
              </Card.Title>
              <br />
              <Card.Text>
                <h6>
                  Switches ToR de 1 GbE diseñados para añadir de forma fiable
                  servidores o almacenamiento en rack a través de
                  implementaciones rentables.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Una familia de switches de gama baja con excelente
                  rendimiento y baja latencia.
                </h6>
                <h6>-Obtenga más agilidad y opciones con menos costes.</h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
            <Card.Img
              variant="top"
              src="./img/switches10gbe.avif"
              height={300}
            />
            </div>
            
            <Card.Body>
              <Card.Title className="text-info">
                <h2>Switches Dell PowerSwitch serie S de 10 GbE</h2>
              </Card.Title>
              <br />
              <Card.Text>
                <h6>
                  Switches ToR de 10 GbE diseñados para ofrecer flexibilidad y
                  alto rendimiento para las cargas de trabajo y las aplicaciones
                  más exigentes de hoy en día.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Una familia de switches de gama media que le permiten
                  encontrar la solución ideal para las necesidades específicas
                  de su centro de datos en rack.
                </h6>
                <h6>
                  -Ofrece baja latencia, excelente rendimiento y alta densidad.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <br/>

        <CardGroup >
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
            <Card.Img
              variant="top"
              src="./img/switches25gbe.avif"
              height={300}
            />

            </div>
            
            <Card.Body>
              <Card.Title className="text-info">
                <h3>Switches Dell PowerSwitch serie S de más de 25 GbE</h3>
              </Card.Title>
              <br />
              <Card.Text>
                <h6>
                  Switches ToR de 10/25/40/50/100/200/400 GbE diseñados para
                  crear estructuras de red de alta capacidad fáciles de
                  implementar y rentables que proporcionan una ruta clara hacia
                  centros de datos definidos por software.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Una familia de switches de alto rendimiento y alta densidad.
                </h6>
                <h6>-Obtenga densidad, rentabilidad y rendimiento óptimos.</h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
            <Card.Img
              variant="top"
              src="./img/SwitchesZgbe.avif"
              height={300}
            />

            </div>
            
            <Card.Body>
              <Card.Title className="text-info">
                <h3>Switches Dell PowerSwitch serie Z</h3>
              </Card.Title>
              <br />
              <Card.Text>
                <h6>
                  Switches de núcleo o agregación de 100 GbE y 400 GbE diseñados
                  para crear tejidos de hoja o laterales en centro de datos
                  optimizados de prácticamente cualquier tamaño.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Una familia de switches flexibles de 100 GbE y 400 GbE de
                  múltiples velocidades y de alto rendimiento para centros de
                  datos preparados para el futuro
                </h6>
                <h6>
                  -Proporciona la flexibilidad necesaria para adelantarse a las
                  necesidades de los datos en lugar de reaccionar ante estas
                </h6>
              </Card.Text>
            </Card.Body>
            
          </Card>
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
            <Card.Img
              variant="top"
              src="./img/switches-escalamiento.avif"
              height={300}
            />

            </div>
            
            <Card.Body>
              <Card.Title className="text-info"><h3>Switches para sistemas modulares Dell PowerEdge</h3></Card.Title>
              <br/>
              <Card.Text>
                <h6>Una familia de módulos de conmutación Ethernet y Fibre Channel MX7000, interconexiones y módulos IO FN</h6>
              </Card.Text>
              <Card.Text>
                <h6>-Maximice las exigencias de conectividad de las cargas de trabajo que se ejecutan en los centros de datos actuales</h6>
                <h6>-Menor coste general y mayor simplicidad en la gestión de redes</h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>

      <div className="switches-sucursales">
        <Card
          className="servicio text-center text-light "
          bg="black"
          border="light"
        >
          <h1>Switches de campus o sucursales PowerSwitch</h1>
        </Card>
        <CardGroup className="container">
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
            <Card.Img
              variant="top"
              src="./img/powerswitch-N11.avif"
              height={300}
            />

            </div>
            
            <Card.Body>
              <Card.Title className="text-info">
                <h2>Switch Dell PowerSwitch N1108EP-ON</h2>
              </Card.Title>
              <Card.Text>
                <h6>
                  Switch de redes abiertas completamente gestionado y habilitado
                  para ONIE, ideal para redes GbE de rápido crecimiento que
                  requieren switches de nivel 2, agregación de alta densidad y
                  alimentación a través de Ethernet (PoE).
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>Se ofrece en una configuración:</h6>
              </Card.Text>
              <Card.Text>
                <h6>8 puertos de cobre y PoE</h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-light" border="secondary">
           <div className="overflow">
           <Card.Img
              variant="top"
              src="./img/powerswitch-N32.avif"
              height={300}
            />

           </div>
            
            <Card.Body>
              <Card.Title className="text-info">
                <h2>Switches Dell PowerSwitch serie N3200</h2>
              </Card.Title>
              <Card.Text>
                <h6>
                  Switches PoE 802.3bt de 90 W, varios Gbps, y 8, 24 o 48
                  puertos de varios Gbps, especialmente diseñados para cargas de
                  trabajo modernas y una mayor productividad de los empleados.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>Se ofrecen en 9 configuraciones:</h6>
              </Card.Text>
              <Card.Text>
                <h6>24 puertos de cobre, fibra y PoE+</h6>
                <h6>48 puertos de cobre y PoE+</h6>
                <h6>32 puertos de varios Gbps (1 G/2,5 G/5 G) PoE +</h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>

      <div className="switches-sistemas-modulares">
        <Card
          className="servicio text-center text-light "
          bg="black"
          border="light"
        >
          <h1>Switches para sistemas modulares Dell PowerEdge</h1>
        </Card>
        <CardGroup className="container">
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
            <Card.Img variant="top" src="./img/powerswitch-MX.avif" />
            </div>
            
            <Card.Body>
              <Card.Title className="text-info">
                <h2>Módulos de switches de E/S Dell PowerEdge MX</h2>
              </Card.Title>
              <Card.Text>
                <h6>
                  Módulos de switches de redes abiertas de 25 GbE diseñados para
                  infraestructuras PowerEdge MX para centros de datos con
                  servidores integrados, almacenamiento, redes y gestión
                  unificada.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Una familia de módulos de switches Ethernet y Fibre Channel
                  con capacidades de redes abiertas
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Aproveche una arquitectura de tejido ampliable de varios
                  chasis y los servicios OS10 SmartFabric
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-black text-center text-light" border="secondary">
            <div className="overflow">
            <Card.Img variant="top" src="./img/powerswitch-FN.avif" />

            </div>
            
            <Card.Body>
              <Card.Title className="text-info">
                <h2>Módulos de E/S Dell PowerEdge FN</h2>
              </Card.Title>
              <Card.Text>
                <h6>
                  Simplifique el cableado de FX2 y mejore las capacidades
                  convergentes de E/S y Fibre Channel para la arquitectura FX
                  con el modo de switch de capa completa 2/3 adicional.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Reducción de la complejidad de los cables hasta en 8:1.
                </h6>
              </Card.Text>
              <Card.Text>
                <h6>
                  -Optimización del rendimiento de FX2 para ayudar a mejorar los
                  flujos de tráfico este-oeste.
                </h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <Footer/>

    </>
  );
};

export default Switches;
