import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import "./softwareapp.css";
import "../../../Layouts/Footer";
import Footer from "../../../Layouts/Footer";

const Softwares = () => {
  return (
    <div>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/software-toping.webp" alt="" />
          <Card.ImgOverlay className="main-software">
            <Card.Title className="titulo-software">
              <h1>Software</h1>
            </Card.Title>

            <Card.Text className="subtitulo-software">
              <h5>
                En H&H Solutions contamos con la disponibilidad de software de
                aplicacion como productos al alcance de tus manos.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Productos</h1>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card className="bg-black" border="white">
            <div className="overflow">
                
              <Card.Img variant="top" src="img/VMware_logo-software.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/networker-software.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/Veeam-Prueba.jpg" />
              <Card.ImgOverlay/>
            </div>
          </Card>
        </CardGroup>

        <CardGroup>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/SolarWinds-software.jpg" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/veamone-logo-software.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              <Card.Img variant="top" src="img/kaspersky-new.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
          <Card className="bg-black" border="white">
            <div className="overflow">
              {" "}
              <Card.Img variant="top" src="img/recoverpoint.png" />
              <Card.ImgOverlay/>
            </div>
          </Card>
        </CardGroup>
      </div>

      <Footer />
    </div>
  );
};

export default Softwares;
