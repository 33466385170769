import React from "react";
import { Accordion, Button, Card, CardGroup, Figure } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../../Layouts/Footer";

const Operativa = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/continuidad-operativa.avif" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad" style={{ marginTop: "21%" }}>
            <h1>Continuidad Operativa</h1>
          </Card.Title>
        </Card.ImgOverlay>
      </Card>

      <div className="libreria-container" style={{ marginTop: "1%" }}>
        <div className="libreria-desc">
          <h1>¿Qué ofrecemos?</h1>

          <p>
            En H&H Solutions, te ofrecemos un amplio rango de soluciones para tu
            data center, desde estrategias de prevención, hasta soluciones de
            continuidad de acorde con tus necesidades, grandes o pequeñas, y
            específicas a tu industria. Nos preocupamos por estar siempre al día
            y escuchar tus requerimientos de nivel de servicio.Contáctanos, y
            ¡Acércate a Nosoros!
          </p>
          <Button variant="dark" as={Link} to="/Contactos" size="lg">
            CONTACTA CON NOSOTROS
          </Button>
        </div>
        <div>
          <Figure>
            <Figure.Image
              variant="top"
              className="rounded"
              width={1100}
              height={500}
              alt="171x180"
              src="/img/Operativa2.avif"
              style={{ marginTop: "5%", marginLeft: "-8%" }}
            />
          </Figure>
        </div>
      </div>

      <div style={{ marginTop: "2%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Por tanto lo que proponemos es:</h1>
        </Card>
      </div>

      <div className="text-center text-white" style={{ marginTop: "2%" }}>
        <CardGroup>
          <Card className="bg-black" border="white">
            <Figure>
              <Figure.Image
                variant="top"
                className="rounded"
                width={100}
                height={500}
                alt="171x180"
                src="/img/Operativa3.png"
                style={{ marginTop: "10%" }}
              />
            </Figure>

            <Card.Body>
              <Card.Title className="text-info">
                Solución tecnológica a medida
              </Card.Title>
              <Card.Text>
                Te ofrecemos un análisis de cargas de tu centro de datos.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="bg-black" border="white">
            <Figure>
              <Figure.Image
                variant="top"
                className="rounded"
                width={100}
                height={500}
                alt="171x180"
                src="/img/Operativa4.png"
                style={{ marginTop: "10%" }}
              />
            </Figure>

            <Card.Body>
              <Card.Title className="text-info">
                Analizamos resultados
              </Card.Title>
              <Card.Text>
                Te acompañamos en la lectura y análisis de resultados.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bg-black" border="white">
            <Figure>
              <Figure.Image
                variant="top"
                className="rounded"
                width={100}
                height={500}
                alt="171x180"
                src="/img/Operativa5.png"
                style={{ marginTop: "10%" }}
              />
            </Figure>

            <Card.Body>
              <Card.Title className="text-info">Procesos de gestión</Card.Title>
              <Card.Text>
                Implementamos y damos soporte a la mejor solución para tu
                negocio.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>

      <div style={{ marginTop: "2%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>
            Aquí compartimos algunas de las mejores prácticas para asegurar la
            continuidad en los servicios de nuestros centros de datos:
          </h1>
        </Card>
      </div>

      <div>
        <div className="libreria-container-beneficios ">
          <div className="libreria-desc-beneficios">
            <Accordion defaultActiveKey={["0"]}>
              <Accordion.Item eventKey="0" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Diseño de un plan:</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    La prevención es clave, pero en el caso de lo que no podemos
                    controlar (como los desastres naturales) siempre debemos de
                    tener un plan de recuperación sólido, detallado y ejecutable
                    para desarrollar una estructura.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Prueba tu plan:</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Tener un plan no es suficiente. Muchos planes se ven bien en
                    papel, pero tienen errores graves a la hora de la ejecución.
                    Probar los planes paso a paso, y corregir, puede hacer la
                    diferencia en una situación crítica. ¡Establece pruebas
                    periódicas! Hacer un plan, probarlo una vez, y guardarlo en
                    un cajón por 5 años no es suficiente. Hay que hacer ajustes
                    a tiempo, y realizar pruebas al menos cada 6 meses para
                    asegurarnos que el plan es confiable.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Estableciendo prioridades:</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Todas las compañías, grandes y pequeñas, deben tener un plan
                    de recuperación de datos. Muchas veces los presupuestos no
                    son suficientes para cubrir todo, así que si de escoger se
                    trata, la inversión debe focalizarse en un plan bien
                    delimitado de aplicaciones y datos críticos.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="bg-secondary text-white">
                <Accordion.Header>
                  <h4>Automatizar:</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    En pleno siglo XXI, muchas empresas dependen todavía de
                    procesos manuales para recuperar sus sistemas. La
                    dependencia en los empleados es total, y en caso de
                    desastres mayores (tomemos como ejemplo un huracán) el
                    acceso a los centros de datos puede estar limitado. Una
                    estrategia de automatización quita esta dependencia y puede
                    asegurar el acceso a los sistemas y a la información sin
                    importar las condiciones.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Operativa;
