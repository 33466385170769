import React from "react";
import { Button, Card, Figure } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../Layouts/Footer";

const Outsourcing = () => {
  return (
    <div>
      <div>
        <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/outsourcing-toping.avif" alt="" />
          <Card.ImgOverlay className="main-software">
            <Card.Title className="titulo-software">
              <h1>Outsourcing</h1>
            </Card.Title>

            <Card.Text className="subtitulo-software">
              <h5>
                H&H Solutions ofrece la tercerización de recursos técnicos para
                sus clientes con el fin de proveer una solución a la necesidad
                de talento de TI dentro de sus empresas.​
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>

      <div  className="libreria-container">
        <div className="libreria-desc">
          <h1>-Administrador de Infraestructura:</h1>

          <p >
          Recurso experto colocado por H&H Solutions en la instalación del cliente a modo de outsourcing.


            
          </p>
         
        </div>
        <div>
          <Figure>
            <Figure.Image
              variant="top"
              className="rounded"
              width={1150}
              height={900}
              alt="171x180"
              src="/img/outsourcing1.avif"
              style={{ marginTop: "3%", marginLeft: "-7%" }}
            />
          </Figure>
        </div>
      </div>

      <div
        className="funcionalidad-nube-contenedor"
        
      >
        <div className="funcionalidad-nube-img">
          <Figure>
            <Figure.Image
              className="rounded"
              variant="top"
              width={1200}
              height={180}
              alt=""
              src="./img/outsourcing2.avif"
              style={{ marginTop: "3%", marginLeft: "7%" }}
            />
          </Figure>
        </div>
        <div className="funcionalidad-nube-desc">
          <h1> -Soporte técnico en sitio:​</h1>
          <p style={{marginTop:"4%"}}>
          Recurso colocado por H&H Solutions en la instalación del cliente a
            modo de outsourcing. Tales como:​ Diseño y Desarrollo Web​ y 
            Programación orientada a objeto.​
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Outsourcing;
