import React from "react";
import { Button, Card, CardGroup } from "react-bootstrap";
import Footer from "../../../../Layouts/Footer";

const CiscoNube = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/topnube-cisco.webp" alt="" />
        <Card.ImgOverlay className="main-seguridad">
          <Card.Title className="titulo-seguridad">
            <h1>
              Proteja lo que importa con la nube y la seguridad de las
              aplicaciones
            </h1>
          </Card.Title>
          <br />

          <Card.Text className="subtitulo-seguridad">
            <h5>
              Proteja a sus usuarios, las aplicaciones que compra y las que
              crea. Una plataforma de protección para todos, desde cualquier
              lugar.
            </h5>
          </Card.Text>
        </Card.ImgOverlay>
      </Card>

      <CardGroup style={{ marginTop: "1%" }}>
        <Card
          className="text-light text-center"
          bg="dark"
          border="light"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>Paraguas Cisco</h5>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Proteja a sus usuarios en todas partes en minutos con seguridad
              rápida, flexible y en la nube.
            </Card.Text>
            <br />
            <Button
              href="https://umbrella.cisco.com/?utm_medium=web-referral&utm_source=cisco"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explorar paraguas
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-center"
          bg="light"
          border="light"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>Carga de trabajo segura de Cisco</h5>{" "}
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Proteja los entornos multinube en nubes, aplicaciones y cargas de
              trabajo.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/site/us/en/products/security/secure-workload/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explore la carga de trabajo segura
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-light text-center"
          border="light"
          bg="dark"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>Análisis seguro de la nube de Cisco</h5>{" "}
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Detecte amenazas internas y externas en toda su red, con análisis
              entregados en la nube.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/c/en/us/products/security/stealthwatch-cloud/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explore el análisis seguro de la nube
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-black text-center"
          border="light"
          bg="light"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>Información sobre la nube segura de Cisco</h5>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Obtenga información detallada sobre los activos cibernéticos y la
              postura de seguridad con una solución de seguridad nativa de la
              nube.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/c/en/us/products/security/secure-cloud-insights/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explore las perspectivas de la nube segura
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-light text-center"
          border="light"
          bg="dark"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Header className="text-center">
            <h5>
              Defensa segura contra amenazas de correo electrónico de Cisco
            </h5>{" "}
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Cubra los vacíos en la seguridad del correo de Microsoft Office
              365, con protección adicional para su correo electrónico.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/c/en/us/products/security/cloud-mailbox-defense/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explore la defensa segura contra amenazas de correo electrónico
            </Button>
          </Card.Body>
        </Card>
        <br />
      </CardGroup>

      <br />

      <CardGroup>
        <Card
          className="text-light text-center"
          border="light"
          bg="dark"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Body>
            <Card.Header>
              <h5>Trabajo híbrido seguro</h5>
            </Card.Header>
            <Card.Text>
              Habilite la seguridad en todas partes, para que pueda empoderar a
              los trabajadores en cualquier lugar. 
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/c/en/us/solutions/security/secure-hybrid-work-solution/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explore el trabajo híbrido seguro
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-black text-center"
          border="light"
          bg="light"
          style={{ width: "38rem", height: "20rem" }}
        >
          <Card.Body>
            <Card.Header>
              <h5>Perímetro de servicio de acceso seguro (SASE)</h5>
            </Card.Header>
            <Card.Text>
              Combina funciones de red y seguridad para un acceso seguro a las
              aplicaciones, en cualquier lugar.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/site/us/en/solutions/secure-access-service-edge-sase/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explorar SASE
            </Button>
          </Card.Body>
        </Card>
        <br />

        <Card
          className="text-light text-center"
          border="light"
          bg="dark"
          style={{ width: "58rem", height: "20rem" }}
        >
          <Card.Body>
            <Card.Header>
              <h5>Confianza cero </h5>
            </Card.Header>
            <Card.Text>
              Equilibre la seguridad y la usabilidad mientras protege a los
              usuarios, las redes y las aplicaciones.
            </Card.Text>
            <br />
            <Button
              href="https://www.cisco.com/site/us/en/solutions/security/zero-trust/index.html"
              variant="outline-info"
              style={{ textDecoration: "none" }}
            >
              Explorar Confianza cero
            </Button>
          </Card.Body>
        </Card>
        <br />
      </CardGroup>
      <Footer />
    </div>
  );
};

export default CiscoNube;
