import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import { CardGroup } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import "./cards.css";
import Cover from "../Layouts/Cover";
import Empresa from "./empresa";
import "./empresa.css";
import "../Layouts/Cover.css";
import Info from "./Info";
import Footer from "../Layouts/Footer";
import { useEffect } from "react";


const Inicio = () => {
  useEffect(() => {
    window.scrollTo({top:0, left:0, behavior:'smooth'});
  }, []);
  return (
    <>
      <div className="contenedor-menu">
        <Cover />
        <Card></Card>

        

        <Empresa />
        <Card></Card>

        <Carousel fade interval={null}>
          <Carousel.Item interval={null}>
            <div className="card-group">
              <CardGroup>
                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                
                  as={Link}
                  to="/MenuProductos"
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <Card.Img variant="top" src="./img/ProductoMenu.webp" />
                  </div>

                  <div className="card-body text-center bg-black ">
                    <Card.Body className="card-body text-light text-decoration none">
                      <Card.Title className="text-secondary">
                        Productos
                      </Card.Title>
                      <Card.Text className="texto">
                        Ofrecemos la gama más completa en productos de calidad
                        con proveedores cien por ciento verificados.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>

                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                  as={Link}
                  to="/Soluciones"
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <Card.Img variant="top" src="./img/SolucionMenu.jpg" />
                  </div>

                  <div className="card-body text-center bg-black">
                    <Card.Body className="card-body text-light">
                      <Card.Title className="text-secondary">
                        Soluciones
                      </Card.Title>
                      <Card.Text>
                        Proporcionamos soluciones enfocadas en su negocio a la
                        vanguardia y necesidades del mercado local.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                  as={Link}
                  to="/Servicios"
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <Card.Img variant="top" src="./img/ServicioMenu.webp" />
                  </div>

                  <div className="card-body text-center bg-black">
                    <Card.Body className="card-body text-light">
                      <Card.Title className="text-secondary">
                        Servicios
                      </Card.Title>

                      <Card.Text>
                        A través de nuestros servicios elaboramos informes que
                        ofrezcan una perspectiva objetiva que propicien el buen
                        funcionamiento de su empresa.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </CardGroup>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={null}>
            <div className="card-group">
              <CardGroup>
                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                  as={Link}
                  to="/Relaciones-comerciales"
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <Card.Img variant="top" src="./img/Relaciones.webp  " />
                  </div>

                  <div className="card-body text-center bg-black ">
                    <Card.Body className="card-body text-light text-decoration none">
                      <Card.Title className="text-secondary">
                        Alianzas Estratégicas
                      </Card.Title>
                      <Card.Text>
                        Conozca nuestros aliados estratégicos enfocados en
                        aportar soluciones tecnológicas, logrando mejorar el
                        trabajo en equipo de su empresa.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>

                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                  as={Link}
                  to="/Capacitaciones"
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <Card.Img variant="top" src="./img/capacitaciones.png  " />
                  </div>

                  <div className="card-body text-center bg-black">
                    <Card.Body className="card-body text-light">
                      <Card.Title className="text-secondary">
                        Entrenamiento
                      </Card.Title>
                      <Card.Text>
                        Capacitamos a tu personal y damos solución a sus
                        necesidades con una atención personalizada.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                  
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <a href="https://sites.google.com/view/hyhsolutions/novedades">
                    <Card.Img variant="top" src="./img/Novedades.png" />

                    </a>
                   
                  </div>

                  <div className="card-body text-center bg-black">
                    <Card.Body className="card-body text-light">
                      <Card.Title className="text-secondary">
                        Novedades
                      </Card.Title>

                      <Card.Text>
                        ¿Conoce cuales son las nuevas tendencias en el mercado
                        tecnológico? Mantengase al día de las noticias e
                        informaciones cibernéticas.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </CardGroup>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="card-group">
              <CardGroup>
                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                  as={Link}
                  to="/Soporte"
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <Card.Img variant="top" src="./img/SoporteMenu.avif  " />
                  </div>

                  <div className="card-body text-center bg-black ">
                    <Card.Body className="card-body text-light text-decoration none">
                      <Card.Title className="text-secondary">
                        Soporte Técnico
                      </Card.Title>
                      <Card.Text>
                        Ofrecemos soporte las 24 horas del día, los 7 días de la
                        semana, durante los 365 días del año.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>

                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                 
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <a href="https://sites.google.com/view/hyhsolutions/cotizacion">
                    <Card.Img
                      variant="top"
                      src="./img/CotizacionesMenu.webp  "
                    />

                    </a>
                    
                  </div>

                  <div className="card-body text-center bg-black">
                    <Card.Body className="card-body text-light">
                      <Card.Title className="text-secondary">
                        Cotizaciones
                      </Card.Title>
                      <Card.Text>
                        Necesita una cotización? Complete los datos siguientes,
                        nuestros recursos están a su disposición.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
                <Card
                onClick={() => {window.scrollTo({top:0, left:0, behavior:'auto'})}}
                  as={Link}
                  to="/Sobre-Nosotros"
                  style={{ textDecoration: "none" }}
                >
                  <div className="overflow">
                    <Card.Img variant="top" src="./img/AboutMenu.webp" />
                  </div>

                  <div className="card-body text-center bg-black">
                    <Card.Body className="card-body text-light">
                      <Card.Title className="text-secondary">
                        Sobre Nosotros
                      </Card.Title>

                      <Card.Text>
                        Somos una empresa proveedora de soluciones de tecnología
                        de la información.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </CardGroup>
            </div>
          </Carousel.Item>
        </Carousel>

        <Info />
        <Footer />

        <section>
          <Outlet></Outlet>
        </section>
      </div>
    </>
  );
};

export default Inicio;
