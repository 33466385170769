import React from "react";
import { Card, CardGroup, Figure } from "react-bootstrap";
import "./premisita.css";
import Footer from "../../../Layouts/Footer";
import { Link } from "react-router-dom";
const premisas = () => {
  return (
    <div>
      <Card className=" bg-dark text-white text-center">
        <Card.Img src="img/Premisa-toping.webp" alt="" />
        <Card.ImgOverlay className="main-seguridad" style={{marginTop:"4%"}}>
          <Card.Title className="titulo-seguridad">
            <h1>Premisa</h1>
          </Card.Title>
          <br />

          <Card.Text className="subtitulo-seguridad">
            <h5>
              En H&H Solutions nos hemos caracterizado por anticiparnos a las
              necesidades del mercado local, al ofrecer soluciones visionarias
              de la mano de fabricantes internacionales y por ofrecer a nuestros
              aliados de negocios respuestas integradas que vienen a satisfacer
              determinadas necesidades en dichas organizaciones.
            </h5>
          </Card.Text>
        </Card.ImgOverlay>
      </Card>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Cartera de Nuestros Productos</h1>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Almacenamiento"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="/img/productos-almacenamientos.jpg"
              />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Almacenamientos</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Libreria"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="./img/producto-libreria.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Librerias Quantum</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Networking"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/producto-networking.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">
                Redes y Telecomunicaciones
              </Card.Title>
            </Card.Body>
          </Card>
        </CardGroup>

        <CardGroup>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Productos-de-Oficina"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/producto-oficina.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Oficinas</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Seguridad"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/producto-seguridad.jpg" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Seguridad</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Servidores"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/productos-servidores.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Servidores</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Softwares"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/producto-software.webp" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Software</Card.Title>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>

      <Footer />
    </div>
  );
};

export default premisas;
