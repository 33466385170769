import React from "react";

import Button from "react-bootstrap/Button";
import Footer from "../../../Layouts/Footer";

import "./almacenamiento.css";

function datosprimarios() {
  return (
    <>
      <>
        <div className="datosprimarios-container-PowerMax">
          <div className="datosprimarios-img-PowerMax">
            <img
              variant="top"
              src="./img/PowerMax.avif"
              alt="about"
              height={385}
              width={700}
            ></img>
          </div>
          <div className="about-desc-PowerMax">
            <h1> PowerMax</h1>
            <p>Arquitectura potente, operación simple, innovación confiable.</p>
            <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/powermax.htm#scroll=off&tab0=0">Ver más información</Button>
          </div>
        </div>

        <div className="datosprimarios-container-PowerStore">
          <div className="about-desc-PowerStore">
            <h1> PowerStore</h1>
            <p>Infraestructura centrada en datos, inteligente y adaptable.</p>
            <Button variant="success" href="https://www.dell.com/es-es/dt/storage/powerstore-storage-appliance.htm#scroll=off&tab0=0">Ver más información</Button>
          </div>
          <div className="datosprimarios-img-PowerStore">
            <img
              variant="top"
              src="./img/PowerStore.avif"
              alt="about"
              height={385}
              width={700}
            ></img>
          </div>
        </div>
      </>
      <div className="datosprimarios-container-Unity">
        <div className="datosprimarios-img-Unity">
          <img
            variant="top"
            src="./img/Unity.avif"
            alt="about"
            height={435}
            width={700}
          ></img>
        </div>
        <div className="about-desc-Unity">
          <h1> Unity XT</h1>
          <p>Almacenamiento unificado simple con múltiples nubes.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/unity.htm#scroll=off&tab0=0">Ver más información</Button>
        </div>
      </div>
      <div className="datosprimarios-container-PowerVault">
        <div className="about-desc-PowerVault">
          <h1> PowerVault</h1>
          <p>
            Almacenamiento de nivel inicial accesible optimizado para SAN/DAS.
          </p>
          <Button variant="success" href="https://www.dell.com/es-es/dt/storage/powervault-me5.htm#tab0=0">Ver más información</Button>
        </div>
        <div className="datosprimarios-img-PowerVault">
          <img
            variant="top"
            src="./img/PowerVault.avif"
            alt="about"
            height={435}
            width={700}
          ></img>
        </div>
      </div>

      <div className="datosprimarios-container-VMAX">
        <div className="datosprimarios-img-VMAX">
          <img
            variant="top"
            src="./img/datos-primarios-vmax.avif"
            alt="about"
            height={435}
            width={700}
          ></img>
        </div>
        <div className="about-desc-VMAX">
          <h1> VMAX</h1>
          <p>Almacenamiento de misión crítica a escala.</p>
          <Button variant="outline-info" href="https://www.dell.com/es-es/dt/storage/vmax-all-flash.htm#tab0=0">Ver más información</Button>
        </div>
      </div>
      <Footer/>

    </>
  );
}

export default datosprimarios;
