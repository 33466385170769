import React from "react";
import { Accordion, CardGroup, Figure, ListGroup } from "react-bootstrap";


import Card from "react-bootstrap/Card";
import "./libreria.css";
import Footer from "../../../Layouts/Footer";

const Quantum = () => {

  return (
    <div>
      <div className="top-libreria">
        <Card className=" bg-dark text-white text-center">
          <Card.Img src="img/libreria.jpg" alt="" />
          <Card.ImgOverlay className="main-libreria">
            <Card.Title className="titulo-libreria" >
              <h1>Librerias Quantum</h1>
            </Card.Title>

            <Card.Text className="subtitulo-libreria">
              <h5>
                Para 2025, el 80 % de los datos generados datos no
                estructurados: imágenes de video, datos de sensores de IoT,
                contenido digital y más. Quantum ofrece soluciones de software
                integrales para almacenar, administrar, enriquecer y archivar
                datos estructurados y no estructurados durante todo el ciclo de
                vida.Las organizaciones de todo el mundo confían en Quantum para
                sus necesidades de datos porque sabemos que la diferencia está
                en sus datos.
              </h5>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>

      <div className="libreria-container">
        <div className="libreria-desc">
          <h1>
            Almacenamiento de datos a largo plazo confiable y de bajo costo para
            un crecimiento masivo de datos
          </h1>

          <p>
            La cinta es la opción de almacenamiento mas segura, económica y que
            requiere muy poca energía para almacenar datos durante períodos
            prolongados; y es un componente crucial en la lucha contra las ciber
            amenazas. La cinta también se ha convertido en una parte clave de
            las infraestructuras de nube e hiper-escala porque proporciona
            almacenamiento seguro a largo plazo para el cumplimiento, los medios
            digitales y el archivo de imágenes. Como resultado, los centros de
            datos más grandes del planeta utilizan cintas de forma generalizada.
          </p>
        </div>
        <div>
          <Figure>
            <Figure.Image
              variant="top"
              className="rounded"
              width={1200}
              height={500}
              alt="171x180"
              src="/img/libreria-top2.webp"
              style={{ marginTop: "10%", marginLeft: "-5%" }}
            />
          </Figure>
        </div>
      </div>
      <br />

      <div className="libreria-top3-container text-center">
        <div className="libreria-top3-desc">
          <h1>
            Las bibliotecas de cintas Quantum Scalar facilitan la gestión de
            cintas
          </h1>
          <p>
            Las bibliotecas de cintas Quantum Scalar® comienzan tan pequeñas
            como tres unidades de rack y 25 ranuras y pueden escalar hasta más
            de 20 racks. Cada módulo ofrece licencias Capacity-on-Demand (CoD),
            con diseños configurables para adaptarse al crecimiento de los
            datos.
          </p>
          <img
            className="container text-center"
            variant="top"
            src="./img/libreria-top3.webp"
            alt="about"
            height={400}
            width={800}
          ></img>
        </div>
      </div>

      <div className="libreria-top4-container text-center">
        <div className="libreria-top4-desc">
          <p>
            El software de diagnóstico proactivo Scalar iLayer™ supervisa
            constantemente el entorno de la biblioteca de cintas, incluidos los
            medios, las unidades de cinta y la propia biblioteca de cintas. Las
            condiciones degradadas, incluidas las condiciones de los medios y
            las unidades, se pueden detectar y alertar de manera proactiva para
            que la cinta sea más fácil de administrar y minimizar el tiempo de
            inactividad.
          </p>
          <img
            className="container text-center"
            variant="top"
            src="./img/libreria-top4.jpg"
            alt="about"
            height={400}
            width={700}
          ></img>
        </div>
      </div>
      <div>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Comparación de Productos</h1>
        </Card>
      </div>
      <br />

      <div>
        <CardGroup className="text-center">
          <Card
            className="bg-white text-center text-black"
            border="secondary"
            style={{ width: "18rem" }}
          >
            <div className="overflow">
              <Card.Img
                className="bg-black"
                variant="top"
                src="./img/SCALAR-I3.png"
              />
            </div>

            <Card.Body>
              <Card.Title className="text-secondary">
                <h3>Escalar i3</h3>
              </Card.Title>
              <Card.Text>
                La Biblioteca de cintas Scalar i3 está diseñada para simplicar
                su uso desde la configuración inicial hasta la gestión continua.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>Números de Cintas: 25-400</ListGroup.Item>
              <ListGroup.Item>
                Capacidad bajo demanda (CoD): Incrementos de 25 ranuras
              </ListGroup.Item>
              <ListGroup.Item>
                Número y tipo de unidades de cinta: 1-24 unidades de cinta de
                altura media
              </ListGroup.Item>
              <ListGroup.Item>Carga Masiva: 0-100 ranuras I/E</ListGroup.Item>
            </ListGroup>
          </Card>

          <Card
            className="bg-white text-center text-black"
            border="secondary"
            style={{ width: "18rem" }}
          >
            <div className="overflow">
              <Card.Img
                className="bg-black"
                variant="top"
                src="./img/SCALAR--I6.png"
              />
            </div>

            <Card.Body>
              <Card.Title className="text-secondary">
                <h3>Escalar i6</h3>
              </Card.Title>
              <Card.Text>
                La Biblioteca de cintas Scalar i3 está diseñada para simplicar
                su uso desde la configuración inicial hasta la gestión continua.
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush ">
              <ListGroup.Item>Números de Cintas: 50-800</ListGroup.Item>
              <ListGroup.Item>
                Capacidad bajo demanda (CoD): Incrementos de 25 ranuras
              </ListGroup.Item>
              <ListGroup.Item>
                Número y tipo de unidades de cinta: 1-24 unidades de cinta de
                altura completa
              </ListGroup.Item>
              <ListGroup.Item>Carga Masiva: 0-240 ranuras I/E</ListGroup.Item>
            </ListGroup>
          </Card>

          <Card
            className="bg-white text-center text-black"
            border="secondary"
            style={{ width: "18rem" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="./img/SCALAR-I6000.png"
                className="bg-black"
              />
            </div>

            <Card.Body>
              <Card.Title className="text-secondary">
                <h3>Escalar i6000</h3>
              </Card.Title>
              <Card.Text>
                La Scalar i6000 es la biblioteca Enterprise con la mejor
                combinación de espacio, seguridad y economía de la industria.
                Una sola unidad puede guardar hasta 360 PB de datos, y se escala
                utilizando el aumento de la capacidad bajo demanda y los precios
                basados ​​en ranuras, para alcanzar el almacenamiento más denso
                y económico ahora y en el futuro
              </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>Números de Cintas: 100-12, 006</ListGroup.Item>
              <ListGroup.Item>
                Capacidad bajo demanda (CoD): Incrementos de 100 ranuras
              </ListGroup.Item>
              <ListGroup.Item>
                Número y tipo de unidades de cinta: Unidades de cinta de altura
                completa 1-192
              </ListGroup.Item>
              <ListGroup.Item>Carga Masiva: 24-1104 ranuras I/E</ListGroup.Item>
            </ListGroup>
          </Card>
        </CardGroup>
        <br />
      </div>

      <div className="libreria-container-componentes text-center">
        <div className="libreria-desc-componentes text-center">
          <h1>
            Componentes clave que hacen que las bibliotecas de cintas Quantum
            Scalar sean las más seguras
          </h1>
          <p>
            Las bibliotecas de cintas escalares ofrecen una solución resistente
            a la cibernética que puede ser tan pequeña como unas pocas cintas,
            escalar dentro de un rack o puede comenzar en un rack completo para
            crecer de acuerdo con sus necesidades de almacenamiento. Nuestras
            funciones de seguridad automatizadas le permiten almacenar y
            administrar de manera eficiente sus copias fuera de línea y
            mantenerlas fuera del alcance del ransomware u otro malware.
          </p>

          <p>
            Scalar Security Framework incluye funciones exclusivas con niveles
            de seguridad cada vez mayores, como Active Vault, Logical Tape
            Blocking y Ransom Block.
          </p>
        </div>
      </div>

      <div>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Los beneficios de una biblioteca de cintas escalar</h1>
        </Card>
      </div>

      <div className="libreria-container-beneficios">
        <div className="libreria-desc-beneficios">
          <Accordion defaultActiveKey={["0"]} flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h4>Almacenamiento a largo plazo al menor costo</h4>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  La cinta LTO continúa brindando la solución de almacenamiento
                  a largo plazo de menor costo, y para los conjuntos de datos a
                  escala PB es una fracción del costo de la nube pública y otras
                  soluciones de almacenamiento en frío. Los sistemas Scalar Tape
                  reducen aún más el costo total con diseños que ahorran
                  espacio, diagnósticos y análisis iLayer® Proactive, monitoreo
                  e informes automatizados e integración con el software AIOps
                  basado en la nube para reducir el tiempo administrativo.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h4>Garantice la integridad y la seguridad de los datos</h4>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Nuestras bibliotecas de cintas escalares son las bibliotecas
                  de cintas más seguras del mercado, con más de 25
                  características y capacidades únicas que forman un marco de
                  seguridad integral que controla el acceso al sistema,
                  proporciona monitoreo del sistema y detección de eventos,
                  seguridad y cifrado de datos, y características únicas para la
                  protección cibernética. e integridad de los datos.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h4>Administre fácilmente el crecimiento de datos</h4>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Un diseño modular que simplifica la adición de ranuras de
                  almacenamiento para el crecimiento de la capacidad y unidades
                  para un mayor rendimiento. Las licencias de ranuras de
                  capacidad bajo demanda (CoD) brindan un nivel de granularidad
                  de almacenamiento para ayudarlo a administrar mejor sus costos
                  de almacenamiento en un enfoque de pago a medida que crece con
                  escalamiento de capacidad de 1,125 TB hasta 540 PB basado en
                  LTO-9 comprimido. capacidad.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <h4>Minimice el tiempo de inactividad</h4>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Un conjunto integral de funciones de alta disponibilidad para
                  garantizar que el sistema permanezca operativo y accesible.
                  Las fuentes de alimentación redundantes y la conmutación por
                  error de ruta (tanto para el control robótico como para la
                  ruta de datos) brindan la tolerancia a fallas necesaria en un
                  sistema empresarial. Además, la inteligencia integrada en el
                  software iLayer brinda un mayor nivel de disponibilidad, ya
                  que las características avanzadas, como el monitoreo ambiental
                  y de energía, el diagnóstico proactivo y los informes de
                  medios y unidades de cinta, identifican tendencias y notifican
                  a los usuarios sobre posibles problemas antes de que ocurran
                  fallas, lo que permite un control proactivo de la biblioteca.
                  sistema para garantizar el máximo tiempo de actividad.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      ></div>

      

      <Footer />
    </div>
  );
};

export default Quantum;
