import React from "react";
import { Card, CardGroup, Carousel, Figure } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../Layouts/Footer";
import almacenar from "../.././Media/MenuSoluciones-Toping.mp4";
const SolucionesServicios = () => {
  return (
    <div>
      <div className="cover-container-almacenamiento">
        <video
          className="video-almacenamiento"
          src={almacenar}
          autoPlay
          loop
          muted
        ></video>
        <h2>Soluciones</h2>

        <p>
          Somos conscientes de que tu empresa tiene diferentes necesidades y
          queremos acompañarte a resolverlas. Por ello, en H&H Solutions te ofrecemos
          distintos productos que te ayudarán a que tu negocio crezca de forma
          segura. Con una gestión ágil y adaptados a tu realidad.
        </p>
      </div>

      <div style={{ marginTop: "1%" }}>
        <Card className=" text-center text-light " bg="black" border="light">
          <h1>Nuestra Cartera de Soluciones</h1>
        </Card>
      </div>

      <div style={{ marginTop: "1%" }}>
        <CardGroup>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Premisa"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/soluciones-premisa.avif" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Premisa</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Cloud"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="./img/solucion-nube.avif" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Soluciones Cloud</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Proteccion-de-datos"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="/img/solucion-protecciondatos.avif"
              />
            </div>

            <Card.Body>
              <Card.Title className="text-white">
                Soluciones de Protección de Datos
              </Card.Title>
            </Card.Body>
          </Card>

          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Soluciones-de-seguridad"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/soluciones-seguridad.avif" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">
                Soluciones de Seguridad
              </Card.Title>
            </Card.Body>
          </Card>
        </CardGroup>

        <CardGroup>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Disaster-Recovery"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img
                variant="top"
                src="/img/solucion-disasterrecovery.jpg"
              />
            </div>

            <Card.Body>
              <Card.Title className="text-white">Disaster Recovery</Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Continuidad-Operativa"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/solucion-continuidad.avif" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">
                Continuidad Operativa
              </Card.Title>
            </Card.Body>
          </Card>
          <Card
            className="text-center bg-black text-white"
            border="white"
            as={Link}
            to="/Boveda-protegida-de-Datos"
            style={{ textDecoration: "none" }}
          >
            <div className="overflow">
              <Card.Img variant="top" src="/img/solucion-boveda.avif" />
            </div>

            <Card.Body>
              <Card.Title className="text-white">
                Bóveda Protegida de Datos
              </Card.Title>
            </Card.Body>
          </Card>

          <Card className="text-center text-white">
            <div className="overflow">
              <a href="https://www.cisco.com/site/us/en/products/security/endpoint-security/secure-endpoint/index.html">
                <Card.Img variant="top" src="img/solucion-endpoint.avif" />
              </a>
            </div>

            <Card.Body className="bg-black">
              <Card.Title className="text-white">
                Soluciones End Point
              </Card.Title>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>

      <Footer />
    </div>
  );
};
export default SolucionesServicios;
